var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              key: _vm.planTab.tabName,
              attrs: {
                label: _vm.$t("inspection.planName"),
                name: _vm.planTab.tabName,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    model: _vm.inspectionPlan.listQuery,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("inspection.plan.name") },
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", clearable: "" },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.searchCondition.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.inspectionPlan.listQuery
                                      .inspectionPlanName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionPlan.listQuery,
                                      "inspectionPlanName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "inspectionPlan.listQuery.inspectionPlanName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.company.name") } },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  on: {
                                    change: _vm.companyChange,
                                    clear: function ($event) {
                                      return _vm.clearEmpty("companyId")
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.inspectionPlan.listQuery.companyId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionPlan.listQuery,
                                        "companyId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionPlan.listQuery.companyId",
                                  },
                                },
                                _vm._l(_vm.companyList, function (item) {
                                  return _c("el-option", {
                                    key: item.companyId,
                                    attrs: {
                                      label: item.companyName,
                                      value: item.companyId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("base.project.name") } },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "project",
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.inspectionPlan.listQuery.projectId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionPlan.listQuery,
                                        "projectId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionPlan.listQuery.projectId",
                                  },
                                },
                                _vm._l(_vm.projectList, function (item) {
                                  return _c("el-option", {
                                    key: item.projectId,
                                    attrs: {
                                      label: item.projectName,
                                      value: item.projectId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("inspection.plan.ifEnable"),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  style: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.inspectionPlan.listQuery.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionPlan.listQuery,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionPlan.listQuery.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: {
                                      label: _vm.$t("commons.yes"),
                                      value: "1",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "0",
                                    attrs: {
                                      label: _vm.$t("commons.no"),
                                      value: "0",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "计划类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.inspectionPlan.listQuery.planType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inspectionPlan.listQuery,
                                        "planType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "inspectionPlan.listQuery.planType",
                                  },
                                },
                                _vm._l(_vm.planArrs, function (it, dx) {
                                  return _c("el-option", {
                                    key: dx,
                                    attrs: { label: it.name, value: it.val },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "filter-container",
                            staticStyle: { "margin-left": "12%" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-search",
                                  loading: _vm.inspectionPlan.listLoading,
                                },
                                on: { click: _vm.searchCondition },
                              },
                              [_vm._v(_vm._s(_vm.$t("commons.search")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-brush",
                                  plain: "",
                                },
                                on: { click: _vm.clearQueryForm },
                              },
                              [_vm._v(_vm._s(_vm.$t("commons.reset")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPlanEditTab(null)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("inspection.plan.add")))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-body" },
                [
                  _c("TTable", {
                    ref: "pointTable",
                    attrs: {
                      tableTitle: _vm.tableTitle,
                      tableData: _vm.inspectionPlan.list,
                      serialNumber: "",
                      tbloading: _vm.inspectionPlan.listLoading,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "yesEnable",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.switchChange(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "operating",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: scope.row.processing,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPlanEditTab(
                                      scope.row.inspectionPlanId
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: scope.row.processing,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openPlanOrderTab(scope.row)
                                  },
                                },
                              },
                              [_vm._v("清单")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.tbRowTemporary(scope.row)
                                  },
                                },
                              },
                              [_vm._v("临时下发")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downQrCodes(scope.row)
                                  },
                                },
                              },
                              [_vm._v("下载二维码")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: scope.row.status == 1,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteInspection(
                                      scope.row.inspectionPlanId
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.inspectionPlan.total > 0,
                    expression: "inspectionPlan.total > 0",
                  },
                ],
                attrs: {
                  total: _vm.inspectionPlan.total,
                  page: _vm.inspectionPlan.listQuery.current,
                  limit: _vm.inspectionPlan.listQuery.rowCount,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(
                      _vm.inspectionPlan.listQuery,
                      "current",
                      $event
                    )
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(
                      _vm.inspectionPlan.listQuery,
                      "rowCount",
                      $event
                    )
                  },
                  pagination: _vm.getInspectionPage,
                },
              }),
            ],
            1
          ),
          _vm.editTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.editTab.tabName,
                  attrs: {
                    label: _vm.editTitle,
                    name: _vm.editTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoad,
                          expression: "isLoad",
                        },
                      ],
                      ref: "inspectionPlanForm",
                      attrs: {
                        model: _vm.inspectionPlan.form,
                        rules: _vm.inspectionPlan.formRules,
                        "label-width": "100px",
                        "element-loading-background": "rgba(255,255,255,1)",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _vm.isEdit == false
                            ? [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "所属类型",
                                          prop: "planType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { clearable: "" },
                                            model: {
                                              value:
                                                _vm.inspectionPlan.form
                                                  .planType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inspectionPlan.form,
                                                  "planType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "inspectionPlan.form.planType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.planArrs,
                                            function (it, dx) {
                                              return _c("el-option", {
                                                key: dx,
                                                attrs: {
                                                  label: it.name,
                                                  value: it.val,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("base.company.name"),
                                          prop: "companyId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "hidden",
                                          attrs: { type: "hidden" },
                                          model: {
                                            value:
                                              _vm.inspectionPlan.form
                                                .inspectionPlanId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inspectionPlan.form,
                                                "inspectionPlanId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "inspectionPlan.form.inspectionPlanId",
                                          },
                                        }),
                                        _c(
                                          "el-select",
                                          {
                                            style: { width: "100%" },
                                            attrs: { clearable: "" },
                                            on: {
                                              change: _vm.companyEditChange,
                                            },
                                            model: {
                                              value:
                                                _vm.inspectionPlan.form
                                                  .companyId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inspectionPlan.form,
                                                  "companyId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "inspectionPlan.form.companyId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.companyList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.companyId,
                                                attrs: {
                                                  label: item.companyName,
                                                  value: item.companyId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.$t("base.project.name"),
                                          prop: "projectId",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            style: { width: "100%" },
                                            attrs: { clearable: "" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getProjectPositionTree(
                                                  _vm.inspectionPlan.form
                                                    .projectId
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.inspectionPlan.form
                                                  .projectId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.inspectionPlan.form,
                                                  "projectId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "inspectionPlan.form.projectId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.editProjectList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.projectId,
                                                attrs: {
                                                  label: item.projectName,
                                                  value: item.projectId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t("inspection.plan.name"),
                                    prop: "inspectionPlanName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "40",
                                      "show-word-limit": true,
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.inspectionPlan.form
                                          .inspectionPlanName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inspectionPlan.form,
                                          "inspectionPlanName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inspectionPlan.form.inspectionPlanName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "inspection.plan.executePosition"
                                    ),
                                    prop: "rootPositionId",
                                  },
                                },
                                [
                                  _c("tree-select", {
                                    attrs: {
                                      disabled: _vm.isEdit,
                                      multiple: false,
                                      "show-count": true,
                                      noOptionsText: "没有配置",
                                      placeholder: _vm.$t(
                                        "commons.selectPlease"
                                      ),
                                      options: _vm.editPositionList,
                                    },
                                    model: {
                                      value:
                                        _vm.inspectionPlan.form.rootPositionId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inspectionPlan.form,
                                          "rootPositionId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inspectionPlan.form.rootPositionId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "inspection.plan.advanceNotify"
                                    ),
                                    prop: "advanceTime",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form.advanceTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "advanceTime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.advanceTime",
                                      },
                                    },
                                    _vm._l(_vm.TimeOptions, function (item) {
                                      return _c("el-option", {
                                        key: item,
                                        attrs: {
                                          label: item + "分钟",
                                          value: item,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "inspection.plan.effectiveTime"
                                    ),
                                    prop: "effectDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      "value-format": "timestamp",
                                      placeholder: _vm.$t("commons.selectDate"),
                                    },
                                    model: {
                                      value: _vm.inspectionPlan.form.effectDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inspectionPlan.form,
                                          "effectDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inspectionPlan.form.effectDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "inspection.plan.invalidTime"
                                    ),
                                    prop: "expireDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    style: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      "value-format": "timestamp",
                                      placeholder: _vm.$t("commons.selectDate"),
                                    },
                                    on: { change: _vm.expiredateChange },
                                    model: {
                                      value: _vm.inspectionPlan.form.expireDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inspectionPlan.form,
                                          "expireDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inspectionPlan.form.expireDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务周期",
                                    prop: "cycleType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      on: { change: _vm.changeExeDutyCycle },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form.cycleType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "cycleType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.cycleType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        key: "1",
                                        attrs: {
                                          label: _vm.$t("commons.monthly"),
                                          value: "1",
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "2",
                                        attrs: {
                                          label: _vm.$t("commons.weekly"),
                                          value: "2",
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "3",
                                        attrs: {
                                          label: _vm.$t("commons.daily"),
                                          value: "3",
                                        },
                                      }),
                                      _c("el-option", {
                                        key: "4",
                                        attrs: {
                                          label: _vm.$t("commons.each"),
                                          value: "4",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "inspection.plan.executeDuty"
                                    ),
                                    prop: "dutyIds",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      style: { width: "100%" },
                                      attrs: {
                                        "collapse-tags": "",
                                        "multiple-limit": 9,
                                        filterable: "",
                                        multiple: "",
                                      },
                                      model: {
                                        value: _vm.exePlanCycle.dutyIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.exePlanCycle,
                                            "dutyIds",
                                            $$v
                                          )
                                        },
                                        expression: "exePlanCycle.dutyIds",
                                      },
                                    },
                                    _vm._l(_vm.editDutyList, function (item) {
                                      return _c("el-option", {
                                        key: item.dutyId,
                                        attrs: {
                                          label:
                                            item.dutyName +
                                            "(" +
                                            item.deptName +
                                            ")",
                                          value: _vm.duty2String(item),
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.exePlanCycle.list, function (item, index) {
                            return [
                              _c(
                                "div",
                                { key: index },
                                [
                                  item.cycleType == 1
                                    ? [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.startTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        style: { width: "50%" },
                                                        model: {
                                                          value: item.startDay,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "startDay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.startDay",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.nowDayList,
                                                        function (arr) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: arr.count,
                                                              attrs: {
                                                                label:
                                                                  arr.count +
                                                                  _vm.$t(
                                                                    "inspection.plan.day"
                                                                  ),
                                                                value:
                                                                  arr.count,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c("el-time-select", {
                                                      style: { width: "50%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.startTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.startTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "startTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.startTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.endTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        style: { width: "50%" },
                                                        model: {
                                                          value: item.endDay,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "endDay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.endDay",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.nowDayList,
                                                        function (arr) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: arr.count,
                                                              attrs: {
                                                                label:
                                                                  arr.count +
                                                                  _vm.$t(
                                                                    "inspection.plan.day"
                                                                  ),
                                                                value:
                                                                  arr.count,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c("el-time-select", {
                                                      style: { width: "50%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.endTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.endTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "endTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.endTime",
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "10px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteExePlanDate(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-plus",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "76px",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addExePlanDate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  item.cycleType == 2
                                    ? [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.startTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        style: { width: "50%" },
                                                        model: {
                                                          value:
                                                            item.startWeekDay,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "startWeekDay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.startWeekDay",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.weekList,
                                                        function (arr) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: arr.value,
                                                              attrs: {
                                                                label:
                                                                  arr.label,
                                                                value:
                                                                  arr.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c("el-time-select", {
                                                      style: { width: "50%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.startTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.startTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "startTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.startTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.endTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        style: { width: "50%" },
                                                        model: {
                                                          value:
                                                            item.endWeekDay,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "endWeekDay",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.endWeekDay",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.weekList,
                                                        function (arr) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: arr.value,
                                                              attrs: {
                                                                label:
                                                                  arr.label,
                                                                value:
                                                                  arr.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                    _c("el-time-select", {
                                                      style: { width: "50%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.endTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.endTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "endTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.endTime",
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "10px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteExePlanDate(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-plus",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "76px",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addExePlanDate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  item.cycleType == 3
                                    ? [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.startTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("el-time-select", {
                                                      style: { width: "100%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.startTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.startTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "startTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.startTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 8 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.endTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("el-time-select", {
                                                      style: { width: "69%" },
                                                      attrs: {
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.endTime"
                                                          ),
                                                        "picker-options": {
                                                          start: "00:00",
                                                          step: "00:05",
                                                          end: "23:59",
                                                        },
                                                      },
                                                      model: {
                                                        value: item.endTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "endTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.endTime",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "inspection.plan.crossDay"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                    _c("el-switch", {
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "10px",
                                                        marginTop: "9px",
                                                      },
                                                      attrs: {
                                                        "active-value": "Y",
                                                        "inactive-value": "N",
                                                      },
                                                      model: {
                                                        value: item.nextDayFlag,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "nextDayFlag",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.nextDayFlag",
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "60px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteExePlanDate(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-plus",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "126px",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addExePlanDate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  item.cycleType == 4
                                    ? [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.startTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      style: { width: "100%" },
                                                      attrs: {
                                                        type: "datetime",
                                                        "value-format":
                                                          "yyyy-MM-dd HH:mm",
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.selectDate"
                                                          ),
                                                      },
                                                      model: {
                                                        value: item.startTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "startTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.startTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        _vm.$t(
                                                          "commons.endTime"
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      style: { width: "100%" },
                                                      attrs: {
                                                        type: "datetime",
                                                        "value-format":
                                                          "yyyy-MM-dd HH:mm",
                                                        placeholder:
                                                          _vm.$t(
                                                            "commons.selectDate"
                                                          ),
                                                      },
                                                      model: {
                                                        value: item.endTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "endTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.endTime",
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "10px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteExePlanDate(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("el-button", {
                                                      staticClass:
                                                        "el-icon-plus",
                                                      style: {
                                                        position: "absolute",
                                                        marginLeft: "76px",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addExePlanDate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "督办岗位1" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-plus",
                                        plain: "",
                                        size: "mini",
                                      },
                                      on: { click: _vm.addSupervisoryDuty },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("commons.add")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.supervisoryPlanCycle.dutyList,
                        function (item, index) {
                          return [
                            _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "督办岗位2" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                style: { width: "100%" },
                                                model: {
                                                  value: item.dutyId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "dutyId",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.dutyId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.editDutyList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.dutyId,
                                                    attrs: {
                                                      label:
                                                        item.dutyName +
                                                        "(" +
                                                        item.deptName +
                                                        ")",
                                                      value:
                                                        _vm.duty2String(item),
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: _vm.$t("inspection.cycle"),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                style: { width: "100%" },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changeSupervisoryDutyCycle(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.cycleType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "cycleType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.cycleType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  key: "1",
                                                  attrs: {
                                                    label:
                                                      _vm.$t("commons.monthly"),
                                                    value: "1",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  key: "2",
                                                  attrs: {
                                                    label:
                                                      _vm.$t("commons.weekly"),
                                                    value: "2",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  key: "3",
                                                  attrs: {
                                                    label:
                                                      _vm.$t("commons.daily"),
                                                    value: "3",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  key: "4",
                                                  attrs: {
                                                    label:
                                                      _vm.$t("commons.each"),
                                                    value: "4",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c("el-button", {
                                              staticClass: "el-icon-delete",
                                              style: {
                                                position: "absolute",
                                                marginLeft: "10px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteSupervisoryDuty(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(item.list, function (data, num) {
                                  return [
                                    _c(
                                      "div",
                                      { key: num },
                                      [
                                        data.cycleType == 1
                                          ? [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "startDay",
                                                            label:
                                                              _vm.$t(
                                                                "commons.startTime"
                                                              ),
                                                            rules: [
                                                              {
                                                                required: true,
                                                                validator: (
                                                                  rule,
                                                                  value,
                                                                  callback
                                                                ) =>
                                                                  _vm.validatorstartTime(
                                                                    rule,
                                                                    value,
                                                                    callback,
                                                                    num
                                                                  ),
                                                                trigger: [
                                                                  "blur",
                                                                  "change",
                                                                ],
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              style: {
                                                                width: "50%",
                                                              },
                                                              model: {
                                                                value:
                                                                  data.startDay,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data,
                                                                      "startDay",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.startDay",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.nowDayList,
                                                              function (arr) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: arr.count,
                                                                    attrs: {
                                                                      label:
                                                                        arr.count +
                                                                        _vm.$t(
                                                                          "commons.calendarDay"
                                                                        ),
                                                                      value:
                                                                        arr.count,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "50%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.startTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.startTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "startTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.startTime",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.endTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              style: {
                                                                width: "50%",
                                                              },
                                                              model: {
                                                                value:
                                                                  data.endDay,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data,
                                                                      "endDay",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.endDay",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.nowDayList,
                                                              function (arr) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: arr.count,
                                                                    attrs: {
                                                                      label:
                                                                        arr.count +
                                                                        _vm.$t(
                                                                          "inspection.plan.day"
                                                                        ),
                                                                      value:
                                                                        arr.count,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "50%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.endTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.endTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "endTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.endTime",
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-delete",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "10px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSupervisoryPlanDate(
                                                                  item,
                                                                  num
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "76px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addSupervisoryPlanDate(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        data.cycleType == 2
                                          ? [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.startTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              style: {
                                                                width: "50%",
                                                              },
                                                              model: {
                                                                value:
                                                                  data.startWeekDay,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data,
                                                                      "startWeekDay",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.startWeekDay",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.weekList,
                                                              function (arr) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: arr.value,
                                                                    attrs: {
                                                                      label:
                                                                        arr.label,
                                                                      value:
                                                                        arr.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "50%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.startTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.startTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "startTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.startTime",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.endTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              style: {
                                                                width: "50%",
                                                              },
                                                              model: {
                                                                value:
                                                                  data.endWeekDay,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      data,
                                                                      "endWeekDay",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "data.endWeekDay",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.weekList,
                                                              function (arr) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: arr.value,
                                                                    attrs: {
                                                                      label:
                                                                        arr.label,
                                                                      value:
                                                                        arr.value,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "50%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.endTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.endTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "endTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.endTime",
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-delete",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "10px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSupervisoryPlanDate(
                                                                  item,
                                                                  num
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "76px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addSupervisoryPlanDate(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        data.cycleType == 3
                                          ? [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.startTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.startTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.startTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "startTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.startTime",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.endTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c("el-time-select", {
                                                            style: {
                                                              width: "69%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.endTime"
                                                                ),
                                                              "picker-options":
                                                                {
                                                                  start:
                                                                    "00:00",
                                                                  step: "00:05",
                                                                  end: "23:59",
                                                                },
                                                            },
                                                            model: {
                                                              value:
                                                                data.endTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "endTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.endTime",
                                                            },
                                                          }),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "inspection.plan.crossDay"
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                          _c("el-switch", {
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "10px",
                                                              marginTop: "9px",
                                                            },
                                                            attrs: {
                                                              "active-value":
                                                                "Y",
                                                              "inactive-value":
                                                                "N",
                                                            },
                                                            model: {
                                                              value:
                                                                data.nextDayFlag,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "nextDayFlag",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.nextDayFlag",
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-delete",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "60px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSupervisoryPlanDate(
                                                                  item,
                                                                  num
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "126px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addSupervisoryPlanDate(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        data.cycleType == 4
                                          ? [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.startTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            style: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              type: "datetime",
                                                              "value-format":
                                                                "yyyy-MM-dd HH:mm",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.selectDate"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                data.startTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "startTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.startTime",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 6 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              _vm.$t(
                                                                "commons.endTime"
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            style: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              type: "datetime",
                                                              "value-format":
                                                                "yyyy-MM-dd HH:mm",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "commons.selectDate"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                data.endTime,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    data,
                                                                    "endTime",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "data.endTime",
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-delete",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "10px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteSupervisoryPlanDate(
                                                                  item,
                                                                  num
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("el-button", {
                                                            staticClass:
                                                              "el-icon-plus",
                                                            style: {
                                                              position:
                                                                "absolute",
                                                              marginLeft:
                                                                "76px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addSupervisoryPlanDate(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ]
                        }
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("order.workTimeConfigure"),
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.waitReceiveConfig1"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .unclaimedOvertime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "unclaimedOvertime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.unclaimedOvertime",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.waitReceiveConfig2"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.waitExecuteConfig1"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .firstExecutedOvertime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "firstExecutedOvertime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.firstExecutedOvertime",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.waitExecuteConfig2"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .secondaryExecutedOvertime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "secondaryExecutedOvertime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.secondaryExecutedOvertime",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.waitExecuteConfig3"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.executingConfig1"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .completedOvertime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "completedOvertime",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.completedOvertime",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "order.config.executingConfig2"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig1")
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .timeReportDuty,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "timeReportDuty",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.timeReportDuty",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig2")
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig1")
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .timeReportDept,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "timeReportDept",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.timeReportDept",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig3")
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig1")
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .timeReportSuperiorDept,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "timeReportSuperiorDept",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.timeReportSuperiorDept",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig4")
                                        ) +
                                        "\n                  "
                                    ),
                                    _c("el-input", {
                                      staticClass: "input",
                                      attrs: {
                                        maxlength: "3",
                                        "show-word-limit": true,
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          _vm.inspectionPlan.form
                                            .timeReportSuperiorMax,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.inspectionPlan.form,
                                            "timeReportSuperiorMax",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "inspectionPlan.form.timeReportSuperiorMax",
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("order.config.overtimeConfig5")
                                        ) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24, align: "center" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.createInspectionPlan },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("commons.save")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.orderTab
            ? _c(
                "el-tab-pane",
                {
                  key: _vm.orderTab.tabName,
                  attrs: {
                    label: _vm.$t("inspection.plan.list"),
                    name: _vm.orderTab.tabName,
                    closable: "",
                  },
                },
                [
                  _c("el-row", [
                    _c("div", { staticClass: "topname" }, [
                      _vm._v(
                        "\n            计划名称：" +
                          _vm._s(_vm.planRowData.inspectionPlanName) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          style: {
                            border: "1px solid #DCDFE6",
                            width: "calc(33% - 7px)",
                            height: "510px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { staticClass: "txbtnGroup" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkAllOrClear(1)
                                    },
                                  },
                                },
                                [_vm._v("全选")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "mini" },
                                  on: { click: _vm.saveTreeCheck },
                                },
                                [_vm._v("保存所选")]
                              ),
                            ],
                            1
                          ),
                          _c("el-tree", {
                            ref: "orderTree",
                            staticClass: "orderMinHeight diyTree",
                            attrs: {
                              data: _vm.positionTree,
                              "node-key": "positionId",
                              "show-checkbox": false,
                              "auto-expand-parent": true,
                              "default-expand-all": true,
                              "highlight-current": true,
                            },
                            on: { check: _vm.handlePositionCheckChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ node, data }) {
                                    return _c(
                                      "span",
                                      { staticClass: "custom-tree-node" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              disabled: _vm.isDisable(data),
                                              label: data.isChecked,
                                            },
                                            model: {
                                              value: data.isChecked,
                                              callback: function ($$v) {
                                                _vm.$set(data, "isChecked", $$v)
                                              },
                                              expression: "data.isChecked",
                                            },
                                          },
                                          [_vm._v(_vm._s(node.label))]
                                        ),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              58922173
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          style: {
                            border: "1px solid #DCDFE6",
                            width: "calc(34% - 6px)",
                            margin: "0 10px",
                            height: "510px",
                          },
                        },
                        [
                          _c("div", { staticClass: "xjPositions" }, [
                            _vm._v("\n              巡检位置\n              "),
                            _c(
                              "div",
                              { staticClass: "p_right" },
                              [
                                _vm._v(
                                  "\n                按顺序巡检\n                "
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    staticClass: "mr12",
                                    attrs: { label: "Y" },
                                    on: { change: _vm.sortChange },
                                    model: {
                                      value: _vm.bySort,
                                      callback: function ($$v) {
                                        _vm.bySort = $$v
                                      },
                                      expression: "bySort",
                                    },
                                  },
                                  [_vm._v("是")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "N" },
                                    on: { change: _vm.sortChange },
                                    model: {
                                      value: _vm.bySort,
                                      callback: function ($$v) {
                                        _vm.bySort = $$v
                                      },
                                      expression: "bySort",
                                    },
                                  },
                                  [_vm._v("否")]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "ul",
                            { staticClass: "planOrderUl orderMinHeight" },
                            [
                              _c(
                                "vuedraggable",
                                {
                                  staticClass: "wrapper",
                                  attrs: {
                                    disabled: _vm.isHttping,
                                    animation: 200,
                                  },
                                  on: { end: _vm.sortChange },
                                  model: {
                                    value: _vm.inspectionOrderList,
                                    callback: function ($$v) {
                                      _vm.inspectionOrderList = $$v
                                    },
                                    expression: "inspectionOrderList",
                                  },
                                },
                                [
                                  _c(
                                    "transition-group",
                                    [
                                      _vm._l(
                                        _vm.inspectionOrderList,
                                        function (item, dex) {
                                          return [
                                            _c(
                                              "li",
                                              {
                                                key: item.positionId,
                                                class: {
                                                  liIsActive:
                                                    _vm.tbBypositionId ==
                                                    item.positionId,
                                                },
                                                style: { marginBottom: "2px" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickNode(item)
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sortdex" },
                                                  [_vm._v(_vm._s(dex + 1))]
                                                ),
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.descName) +
                                                    "\n                      "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "delIcon",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.delCheckIt(
                                                          item,
                                                          dex
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("×")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "overyBox" },
                        [
                          _vm.tbBypositionId
                            ? [
                                _c("div", { staticClass: "partName" }, [
                                  _vm._v("巡检要求"),
                                ]),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "planRightOrder orderMinHeight",
                                  },
                                  [
                                    _c("li", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("inspection.position")
                                          ) +
                                          ":" +
                                          _vm._s(
                                            _vm.updateInspectionOrder
                                              .positionName
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                    _c(
                                      "li",
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("inspection.ifScan")
                                            ) +
                                            ":\n                  "
                                        ),
                                        _c("el-switch", {
                                          attrs: {
                                            "active-value": "Y",
                                            disabled:
                                              _vm.updateInspectionOrder
                                                .isPhotograph == "Y",
                                            "inactive-value": "N",
                                          },
                                          model: {
                                            value:
                                              _vm.updateInspectionOrder
                                                .isSignIn,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.updateInspectionOrder,
                                                "isSignIn",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateInspectionOrder.isSignIn",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "li",
                                      [
                                        _vm._v(
                                          "\n          \t\t\t\t是否拍照:\n                  "
                                        ),
                                        _c("el-switch", {
                                          attrs: {
                                            disabled: _vm.disTakePho,
                                            "active-value": "Y",
                                            "inactive-value": "N",
                                          },
                                          on: { change: _vm.takePhotoChange },
                                          model: {
                                            value:
                                              _vm.updateInspectionOrder
                                                .isPhotograph,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.updateInspectionOrder,
                                                "isPhotograph",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateInspectionOrder.isPhotograph",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.updateInspectionOrder.qrText != null
                                      ? [
                                          _c(
                                            "li",
                                            [
                                              _c("vue-qr", {
                                                attrs: {
                                                  text: _vm
                                                    .updateInspectionOrder
                                                    .qrText,
                                                  size: 200,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _c("li", [_vm._v("巡查标准备注:")]),
                                    _c(
                                      "li",
                                      {
                                        on: {
                                          dblclick: function ($event) {
                                            _vm.showAllnotes = true
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            maxlength: "1000",
                                            "show-word-limit": "",
                                            rows: 2,
                                            placeholder: "请输入内容",
                                          },
                                          model: {
                                            value:
                                              _vm.updateInspectionOrder.notes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.updateInspectionOrder,
                                                "notes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "updateInspectionOrder.notes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: _vm.savePositionInspection,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("commons.save")) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.tbBypositionId
                    ? [
                        _c(
                          "el-row",
                          { style: { margin: "20px 0" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled:
                                    _vm.updateInspectionOrder.isPhotograph ==
                                    "Y",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openItemModal(2)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("inspection.addItem")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  disabled:
                                    _vm.updateInspectionOrder.isPhotograph ==
                                    "Y",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openItemModal(1)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("inspection.addDevice")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.inspectionItem.listLoading,
                                    expression: "inspectionItem.listLoading",
                                  },
                                ],
                                attrs: {
                                  data: _vm.inspectionItem.list,
                                  "element-loading-text": "loading",
                                  border: "",
                                  fit: "",
                                  "highlight-current-row": "",
                                  stripe: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { label: _vm.$t("commons.actions") },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    _vm.$t("commons.edit"),
                                                  placement: "bottom",
                                                  "open-delay": _vm.openDelay,
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    icon: "el-icon-edit",
                                                    type: "primary",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateInspectionItem(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  content:
                                                    _vm.$t("commons.delete"),
                                                  placement: "bottom",
                                                  "open-delay": _vm.openDelay,
                                                },
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    size: "mini",
                                                    icon: "el-icon-delete",
                                                    type: "danger",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteInspectionItem(
                                                        scope.row.itemPlanId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2939964494
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: _vm.$t("inspection.name") },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.itemName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3535532340
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: _vm.$t("clocky.jobType") },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "span",
                                              [
                                                scope.row.itemType == 1
                                                  ? [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "inspection.device"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "inspection.item"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3194979560
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: _vm.$t("inspection.ifPhoto"),
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "span",
                                              [
                                                scope.row.isPhotograph == "Y"
                                                  ? [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.yes"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t("commons.no")
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1705561076
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: { label: _vm.$t("commons.template") },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.templateName)
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    996112529
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c("pagination", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.inspectionItem.total > 0,
                                  expression: "inspectionItem.total > 0",
                                },
                              ],
                              attrs: {
                                total: _vm.inspectionItem.total,
                                page: _vm.inspectionItem.listQuery.current,
                                limit: _vm.inspectionItem.listQuery.rowCount,
                              },
                              on: {
                                "update:page": function ($event) {
                                  return _vm.$set(
                                    _vm.inspectionItem.listQuery,
                                    "current",
                                    $event
                                  )
                                },
                                "update:limit": function ($event) {
                                  return _vm.$set(
                                    _vm.inspectionItem.listQuery,
                                    "rowCount",
                                    $event
                                  )
                                },
                                pagination: _vm.getInspectionItemPage,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.inspectionItem.addDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.inspectionItem.addDialogVisible,
                "before-close": _vm.cancelInspectionItemSave,
                width: "20%",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(
                    _vm.inspectionItem,
                    "addDialogVisible",
                    $event
                  )
                },
              },
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _c("title-icon"),
                  _vm._v(_vm._s(_vm.inspectionItem.title) + "\n      "),
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "inspectionItemForm",
                  attrs: {
                    "label-position": "top",
                    model: _vm.inspectionItem.form,
                    rules: _vm.inspectionItemFormRules,
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "hidden",
                    attrs: { type: "hidden" },
                    model: {
                      value: _vm.inspectionItem.form.itemPlanId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectionItem.form, "itemPlanId", $$v)
                      },
                      expression: "inspectionItem.form.itemPlanId",
                    },
                  }),
                  _c("el-input", {
                    staticClass: "hidden",
                    attrs: { type: "hidden" },
                    model: {
                      value: _vm.inspectionItem.form.relationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectionItem.form, "relationId", $$v)
                      },
                      expression: "inspectionItem.form.relationId",
                    },
                  }),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("inspection.template.name"),
                        prop: "templateId",
                      },
                    },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "show-all-levels": false,
                          options: _vm.tempTrees,
                          props: _vm.treesPros,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeTemplate($event)
                          },
                        },
                        model: {
                          value: _vm.inspectionItem.form.templateId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inspectionItem.form, "templateId", $$v)
                          },
                          expression: "inspectionItem.form.templateId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.inspectionItem.label,
                        prop: "itemName",
                      },
                    },
                    [
                      _vm.inspectionItem.form.itemType == 1
                        ? [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "" },
                                on: { change: _vm.changeDevice },
                                model: {
                                  value: _vm.inspectionItem.form.itemName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inspectionItem.form,
                                      "itemName",
                                      $$v
                                    )
                                  },
                                  expression: "inspectionItem.form.itemName",
                                },
                              },
                              _vm._l(
                                _vm.inspectionItem.deviceList,
                                function (item) {
                                  return _c("el-option", {
                                    key: item.deviceId,
                                    attrs: {
                                      value: item.deviceId,
                                      label: item.deviceName,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        : [
                            _c("el-input", {
                              attrs: {
                                maxlength: "50",
                                "show-word-limit": true,
                              },
                              model: {
                                value: _vm.inspectionItem.form.itemName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inspectionItem.form,
                                    "itemName",
                                    $$v
                                  )
                                },
                                expression: "inspectionItem.form.itemName",
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("inspection.ifPhoto") } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": "Y", "inactive-value": "N" },
                        model: {
                          value: _vm.inspectionItem.form.isPhotograph,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inspectionItem.form,
                              "isPhotograph",
                              $$v
                            )
                          },
                          expression: "inspectionItem.form.isPhotograph",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    { on: { click: _vm.cancelInspectionItemSave } },
                    [_vm._v(_vm._s(_vm.$t("commons.cancel")) + "\n        ")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading.fullscreen.lock",
                          value: _vm.inspectionItem.createLoading,
                          expression: "inspectionItem.createLoading",
                          modifiers: { fullscreen: true, lock: true },
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.is_click },
                      on: { click: _vm.handleInspectionItemSave },
                    },
                    [_vm._v(_vm._s(_vm.$t("commons.save")) + "\n        ")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c("TPrintCodes", {
        attrs: { isOpen: _vm.printInfo.open, datas: _vm.printInfo.arrs },
        on: {
          close: function ($event) {
            _vm.printInfo.open = false
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "巡查备注",
            visible: _vm.showAllnotes,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAllnotes = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "padding-bottom": "20px" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "1000",
                  "show-word-limit": "",
                  rows: 30,
                  placeholder: "请输入内容",
                },
                model: {
                  value: _vm.updateInspectionOrder.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.updateInspectionOrder, "notes", $$v)
                  },
                  expression: "updateInspectionOrder.notes",
                },
              }),
              _c(
                "div",
                {
                  staticStyle: { "padding-top": "12px", "text-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showAllnotes = false
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }