<template>
  <div class="app-container">
    <el-tabs v-model="activeTabName" type="card" @tab-remove="removeTab">
      <!--巡检计划-->
      <el-tab-pane
        :label="$t('inspection.planName')"
        :name="planTab.tabName"
        :key="planTab.tabName"
      >
        <el-form
          ref="queryForm"
          :model="inspectionPlan.listQuery"
          label-width="100px"
        >
          <el-row>
            <el-col :span="6">
              <!--计划名称-->
              <el-form-item :label="$t('inspection.plan.name')">
                <el-input
                  v-model="inspectionPlan.listQuery.inspectionPlanName"
                  maxlength="40"
                  clearable
                  @keyup.enter.native="searchCondition"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--公司-->
              <el-form-item :label="$t('base.company.name')">
                <el-select
                  :style="{ width: '100%' }"
                  clearable
                  @change="companyChange"
                  @clear="clearEmpty('companyId')"
                  v-model="inspectionPlan.listQuery.companyId"
                >
                  <el-option
                    v-for="item in companyList"
                    :label="item.companyName"
                    :value="item.companyId"
                    :key="item.companyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--项目名称-->
              <el-form-item :label="$t('base.project.name')">
                <el-select
                  :style="{ width: '100%' }"
                  ref="project"
                  clearable
                  v-model="inspectionPlan.listQuery.projectId"
                >
                  <el-option
                    v-for="item in projectList"
                    :label="item.projectName"
                    :value="item.projectId"
                    :key="item.projectId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!--是否启用-->
              <el-form-item :label="$t('inspection.plan.ifEnable')">
                <el-select
                  :style="{ width: '100%' }"
                  clearable
                  v-model="inspectionPlan.listQuery.status"
                >
                  <el-option
                    :label="$t('commons.yes')"
                    value="1"
                    key="1"
                  ></el-option>
                  <el-option
                    :label="$t('commons.no')"
                    value="0"
                    key="0"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计划类型">
                <el-select style="width:100%" v-model="inspectionPlan.listQuery.planType" clearable >
                  <el-option v-for='(it,dx) in planArrs' :key='dx' :label="it.name" :value="it.val" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            
            <el-col :span="6">
              <div class="filter-container" style="margin-left: 12%;">
			          <el-button
			            type="primary"
			            icon="el-icon-search"
			            :loading="inspectionPlan.listLoading"
			            @click="searchCondition"
			            >{{ $t("commons.search") }}</el-button
			          >
			          <el-button
			            type="primary"
			            icon="el-icon-brush"
			            plain
			            @click="clearQueryForm"
			            >{{ $t("commons.reset") }}</el-button
			          >
			          <el-button
			            type="primary"
			            icon="el-icon-plus"
			            plain
			            @click="openPlanEditTab(null)"
			            >{{ $t("inspection.plan.add") }}</el-button
			          >
			        </div>
              
            </el-col>
          </el-row>
        </el-form>
        
        <div class="table-body">
          <TTable
            ref="pointTable"
            :tableTitle="tableTitle"
            :tableData="inspectionPlan.list"
            serialNumber
            :tbloading="inspectionPlan.listLoading"
          >
            <template slot="yesEnable" slot-scope="scope">
              <!--是否启用-->
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="switchChange(scope.row)"
              ></el-switch>
            </template>
            <template slot="operating" slot-scope="scope">
              <el-button
                type="text"
                :disabled="scope.row.processing"
                @click="openPlanEditTab(scope.row.inspectionPlanId)"
                >编辑</el-button
              >
              <el-button
                type="text"
                :disabled="scope.row.processing"
                @click="openPlanOrderTab(scope.row)"
                >清单</el-button
              >
              <el-button type="text" @click="tbRowTemporary(scope.row)">临时下发</el-button>
              <el-button type="text" @click="downQrCodes(scope.row)"
                >下载二维码</el-button
              >
              <el-button
                type="text"
                :disabled="scope.row.status == 1"
                @click="deleteInspection(scope.row.inspectionPlanId)"
                >删除</el-button
              >
            </template>
          </TTable>
        </div>
        <pagination
          v-show="inspectionPlan.total > 0"
          :total="inspectionPlan.total"
          :page.sync="inspectionPlan.listQuery.current"
          :limit.sync="inspectionPlan.listQuery.rowCount"
          @pagination="getInspectionPage"
        />
      </el-tab-pane>
      <!--新建或编辑巡检计划-->
      <el-tab-pane
        v-if="editTab"
        :label="editTitle"
        :name="editTab.tabName"
        :key="editTab.tabName"
        closable
      >
        <el-form
          ref="inspectionPlanForm"
          :model="inspectionPlan.form"
          :rules="inspectionPlan.formRules"
          label-width="100px"
          v-loading="isLoad"
          element-loading-background="rgba(255,255,255,1)"
        >
          <el-row>
            <template v-if="isEdit == false">
            	<el-col :span="6">
                <el-form-item label="所属类型" prop="planType">
                	<el-select style="width:100%" v-model="inspectionPlan.form.planType" clearable >
	                  <el-option v-for='(it,dx) in planArrs' :key='dx' :label="it.name" :value="it.val" ></el-option>
	                </el-select>
                </el-form-item>
              </el-col>
            	
              <el-col :span="6">
                <el-form-item :label="$t('base.company.name')" prop="companyId">
                  <el-input
                    type="hidden"
                    class="hidden"
                    v-model="inspectionPlan.form.inspectionPlanId"
                  ></el-input>
                  <el-select
                    :style="{ width: '100%' }"
                    clearable
                    @change="companyEditChange"
                    v-model="inspectionPlan.form.companyId"
                  >
                    <el-option
                      v-for="item in companyList"
                      :label="item.companyName"
                      :value="item.companyId"
                      :key="item.companyId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('base.project.name')" prop="projectId">
                  <el-select
                    :style="{ width: '100%' }"
                    @change="
                      getProjectPositionTree(inspectionPlan.form.projectId)
                    "
                    clearable
                    v-model="inspectionPlan.form.projectId"
                  >
                    <el-option
                      v-for="item in editProjectList"
                      :label="item.projectName"
                      :value="item.projectId"
                      :key="item.projectId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </template>
            <el-col :span="6">
              <el-form-item
                :label="$t('inspection.plan.name')"
                prop="inspectionPlanName"
              >
                <el-input
                  maxlength="40"
                  :show-word-limit="true"
                  v-model="inspectionPlan.form.inspectionPlanName"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                :label="$t('inspection.plan.executePosition')"
                prop="rootPositionId"
              >
                <tree-select
                  v-model="inspectionPlan.form.rootPositionId"
                  :disabled="isEdit"
                  :multiple="false"
                  :show-count="true"
                  noOptionsText="没有配置"
                  :placeholder="$t('commons.selectPlease')"
                  :options="editPositionList"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
            	<!--提前通知-->
              <el-form-item
                :label="$t('inspection.plan.advanceNotify')"
                prop="advanceTime"
              >
              <el-select v-model="inspectionPlan.form.advanceTime" placeholder="请选择" clearable>
						    <el-option v-for="item in TimeOptions" :key="item" :label="item+'分钟'" :value="item"></el-option>
						  </el-select>
                <!--<el-input
                  v-model="inspectionPlan.form.advanceTime"
                  maxlength="3"
                  :show-word-limit="true"
                  clearable
                >
                  <span slot="append">{{ $t("commons.minute") }}</span>
                </el-input>-->
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                :label="$t('inspection.plan.effectiveTime')"
                prop="effectDate"
              >
                <el-date-picker
                  :style="{ width: '100%' }"
                  v-model="inspectionPlan.form.effectDate"
                  type="date"
                  value-format="timestamp"
                  :placeholder="$t('commons.selectDate')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                :label="$t('inspection.plan.invalidTime')"
                prop="expireDate"
              >
                <el-date-picker
                  :style="{ width: '100%' }"
                  v-model="inspectionPlan.form.expireDate"
                  type="date"
                  value-format="timestamp"
                  :placeholder="$t('commons.selectDate')"
                  @change="expiredateChange"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
            	<!--巡检周期-->
              <el-form-item label="任务周期" prop="cycleType">
                <el-select
                  :style="{ width: '100%' }"
                  v-model="inspectionPlan.form.cycleType"
                  @change="changeExeDutyCycle"
                >
                  <el-option
                    :label="$t('commons.monthly')"
                    value="1"
                    key="1"
                  ></el-option>
                  <el-option
                    :label="$t('commons.weekly')"
                    value="2"
                    key="2"
                  ></el-option>
                  <el-option
                    :label="$t('commons.daily')"
                    value="3"
                    key="3"
                  ></el-option>
                  <el-option
                    :label="$t('commons.each')"
                    value="4"
                    key="4"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                :label="$t('inspection.plan.executeDuty')"
                prop="dutyIds"
              >
                <el-select
                  :style="{ width: '100%' }"
                  v-model="exePlanCycle.dutyIds"
                  collapse-tags
                  :multiple-limit="9"
                  filterable
                  multiple
                >
                  <el-option
                    v-for="item in editDutyList"
                    :label="item.dutyName + '(' + item.deptName + ')'"
                    :value="duty2String(item)"
                    :key="item.dutyId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div>
            <template v-for="(item, index) in exePlanCycle.list">
              <div :key="index">
                <!--每月-->
                <template v-if="item.cycleType == 1">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.startTime')">
                        <el-select
                          :style="{ width: '50%' }"
                          v-model="item.startDay"
                        >
                          <el-option
                            v-for="arr in nowDayList"
                            :key="arr.count"
                            :label="arr.count + $t('inspection.plan.day')"
                            :value="arr.count"
                          >
                          </el-option>
                        </el-select>
                        <el-time-select
                          :style="{ width: '50%' }"
                          :placeholder="$t('commons.startTime')"
                          v-model="item.startTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.endTime')">
                        <el-select
                          :style="{ width: '50%' }"
                          v-model="item.endDay"
                        >
                          <el-option
                            v-for="arr in nowDayList"
                            :key="arr.count"
                            :label="arr.count + $t('inspection.plan.day')"
                            :value="arr.count"
                          >
                          </el-option>
                        </el-select>
                        <el-time-select
                          :style="{ width: '50%' }"
                          :placeholder="$t('commons.endTime')"
                          v-model="item.endTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '10px' }"
                          @click="deleteExePlanDate(index)"
                          class="el-icon-delete"
                        ></el-button>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '76px' }"
                          @click="addExePlanDate"
                          class="el-icon-plus"
                        ></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
                <!--每周-->
                <template v-if="item.cycleType == 2">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.startTime')">
                        <el-select
                          :style="{ width: '50%' }"
                          v-model="item.startWeekDay"
                        >
                          <el-option
                            v-for="arr in weekList"
                            :key="arr.value"
                            :label="arr.label"
                            :value="arr.value"
                          >
                          </el-option>
                        </el-select>
                        <el-time-select
                          :style="{ width: '50%' }"
                          :placeholder="$t('commons.startTime')"
                          v-model="item.startTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.endTime')">
                        <el-select
                          :style="{ width: '50%' }"
                          v-model="item.endWeekDay"
                        >
                          <el-option
                            v-for="arr in weekList"
                            :key="arr.value"
                            :label="arr.label"
                            :value="arr.value"
                          >
                          </el-option>
                        </el-select>
                        <el-time-select
                          :style="{ width: '50%' }"
                          :placeholder="$t('commons.endTime')"
                          v-model="item.endTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '10px' }"
                          @click="deleteExePlanDate(index)"
                          class="el-icon-delete"
                        ></el-button>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '76px' }"
                          @click="addExePlanDate"
                          class="el-icon-plus"
                        ></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
                <!--每日-->
                <template v-if="item.cycleType == 3">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.startTime')">
                        <el-time-select
                          :style="{ width: '100%' }"
                          :placeholder="$t('commons.startTime')"
                          v-model="item.startTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item :label="$t('commons.endTime')">
                        <el-time-select
                          :style="{ width: '69%' }"
                          :placeholder="$t('commons.endTime')"
                          v-model="item.endTime"
                          :picker-options="{
                            start: '00:00',
                            step: '00:05',
                            end: '23:59',
                          }"
                        >
                        </el-time-select>
                        {{ $t("inspection.plan.crossDay") }}
                        <el-switch
                          :style="{
                            position: 'absolute',
                            marginLeft: '10px',
                            marginTop: '9px',
                          }"
                          v-model="item.nextDayFlag"
                          active-value="Y"
                          inactive-value="N"
                        ></el-switch>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '60px' }"
                          @click="deleteExePlanDate(index)"
                          class="el-icon-delete"
                        ></el-button>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '126px' }"
                          @click="addExePlanDate"
                          class="el-icon-plus"
                        ></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
                <!--单次-->
                <template v-if="item.cycleType == 4">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.startTime')">
                        <el-date-picker
                          :style="{ width: '100%' }"
                          v-model="item.startTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm"
                          :placeholder="$t('commons.selectDate')"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item :label="$t('commons.endTime')">
                        <el-date-picker
                          :style="{ width: '100%' }"
                          v-model="item.endTime"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm"
                          :placeholder="$t('commons.selectDate')"
                        >
                        </el-date-picker>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '10px' }"
                          @click="deleteExePlanDate(index)"
                          class="el-icon-delete"
                        ></el-button>
                        <el-button
                          :style="{ position: 'absolute', marginLeft: '76px' }"
                          @click="addExePlanDate"
                          class="el-icon-plus"
                        ></el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </template>
              </div>
            </template>
          </div>
          <el-row>
            <el-col :span="6">
              <el-form-item label="督办岗位1">
                <el-button
                  type="primary"
                  @click="addSupervisoryDuty"
                  icon="el-icon-plus"
                  plain
                  size="mini"
                >
                  {{ $t("commons.add") }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-for="(item, index) in supervisoryPlanCycle.dutyList">
            <div :key="index">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="督办岗位2">
                    <el-select :style="{ width: '100%' }" v-model="item.dutyId">
                      <el-option
                        v-for="item in editDutyList"
                        :label="item.dutyName + '(' + item.deptName + ')'"
                        :value="duty2String(item)"
                        :key="item.dutyId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item :label="$t('inspection.cycle')">
                    <el-select
                      :style="{ width: '100%' }"
                      v-model="item.cycleType"
                      @change="changeSupervisoryDutyCycle($event, index)"
                    >
                      <el-option
                        :label="$t('commons.monthly')"
                        value="1"
                        key="1"
                      ></el-option>
                      <el-option
                        :label="$t('commons.weekly')"
                        value="2"
                        key="2"
                      ></el-option>
                      <el-option
                        :label="$t('commons.daily')"
                        value="3"
                        key="3"
                      ></el-option>
                      <el-option
                        :label="$t('commons.each')"
                        value="4"
                        key="4"
                      ></el-option>
                    </el-select>
                    <el-button
                      :style="{ position: 'absolute', marginLeft: '10px' }"
                      @click="deleteSupervisoryDuty(index)"
                      class="el-icon-delete"
                    ></el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <template v-for="(data, num) in item.list">
                <div :key="num">
                  <!--每月-->
                  <template v-if="data.cycleType == 1">
                    <el-row>
                      <el-col :span="6">
                        <el-form-item
                          prop="startDay"
                          :label="$t('commons.startTime')"
                          :rules="[
                            {
                              required: true,
                              validator: (rule, value, callback) =>
                                validatorstartTime(rule, value, callback, num),
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-select
                            :style="{ width: '50%' }"
                            v-model="data.startDay"
                          >
                            <el-option
                              v-for="arr in nowDayList"
                              :key="arr.count"
                              :label="arr.count + $t('commons.calendarDay')"
                              :value="arr.count"
                            >
                            </el-option>
                          </el-select>
                          <el-time-select
                            :style="{ width: '50%' }"
                            :placeholder="$t('commons.startTime')"
                            v-model="data.startTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.endTime')">
                          <el-select
                            :style="{ width: '50%' }"
                            v-model="data.endDay"
                          >
                            <el-option
                              v-for="arr in nowDayList"
                              :key="arr.count"
                              :label="arr.count + $t('inspection.plan.day')"
                              :value="arr.count"
                            >
                            </el-option>
                          </el-select>
                          <el-time-select
                            :style="{ width: '50%' }"
                            :placeholder="$t('commons.endTime')"
                            v-model="data.endTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '10px',
                            }"
                            @click="deleteSupervisoryPlanDate(item, num)"
                            class="el-icon-delete"
                          ></el-button>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '76px',
                            }"
                            @click="addSupervisoryPlanDate(item)"
                            class="el-icon-plus"
                          ></el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <!--每周-->
                  <template v-if="data.cycleType == 2">
                    <el-row>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.startTime')">
                          <el-select
                            :style="{ width: '50%' }"
                            v-model="data.startWeekDay"
                          >
                            <el-option
                              v-for="arr in weekList"
                              :key="arr.value"
                              :label="arr.label"
                              :value="arr.value"
                            >
                            </el-option>
                          </el-select>
                          <el-time-select
                            :style="{ width: '50%' }"
                            :placeholder="$t('commons.startTime')"
                            v-model="data.startTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.endTime')">
                          <el-select
                            :style="{ width: '50%' }"
                            v-model="data.endWeekDay"
                          >
                            <el-option
                              v-for="arr in weekList"
                              :key="arr.value"
                              :label="arr.label"
                              :value="arr.value"
                            >
                            </el-option>
                          </el-select>
                          <el-time-select
                            :style="{ width: '50%' }"
                            :placeholder="$t('commons.endTime')"
                            v-model="data.endTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '10px',
                            }"
                            @click="deleteSupervisoryPlanDate(item, num)"
                            class="el-icon-delete"
                          ></el-button>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '76px',
                            }"
                            @click="addSupervisoryPlanDate(item)"
                            class="el-icon-plus"
                          ></el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <!--每日-->
                  <template v-if="data.cycleType == 3">
                    <el-row>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.startTime')">
                          <el-time-select
                            :style="{ width: '100%' }"
                            :placeholder="$t('commons.startTime')"
                            v-model="data.startTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item :label="$t('commons.endTime')">
                          <el-time-select
                            :style="{ width: '69%' }"
                            :placeholder="$t('commons.endTime')"
                            v-model="data.endTime"
                            :picker-options="{
                              start: '00:00',
                              step: '00:05',
                              end: '23:59',
                            }"
                          >
                          </el-time-select>
                          {{ $t("inspection.plan.crossDay") }}
                          <el-switch
                            :style="{
                              position: 'absolute',
                              marginLeft: '10px',
                              marginTop: '9px',
                            }"
                            v-model="data.nextDayFlag"
                            active-value="Y"
                            inactive-value="N"
                          ></el-switch>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '60px',
                            }"
                            @click="deleteSupervisoryPlanDate(item, num)"
                            class="el-icon-delete"
                          ></el-button>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '126px',
                            }"
                            @click="addSupervisoryPlanDate(item)"
                            class="el-icon-plus"
                          ></el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                  <!--单次-->
                  <template v-if="data.cycleType == 4">
                    <el-row>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.startTime')">
                          <el-date-picker
                            :style="{ width: '100%' }"
                            v-model="data.startTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            :placeholder="$t('commons.selectDate')"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item :label="$t('commons.endTime')">
                          <el-date-picker
                            :style="{ width: '100%' }"
                            v-model="data.endTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            :placeholder="$t('commons.selectDate')"
                          >
                          </el-date-picker>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '10px',
                            }"
                            @click="deleteSupervisoryPlanDate(item, num)"
                            class="el-icon-delete"
                          ></el-button>
                          <el-button
                            :style="{
                              position: 'absolute',
                              marginLeft: '76px',
                            }"
                            @click="addSupervisoryPlanDate(item)"
                            class="el-icon-plus"
                          ></el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </template>
                </div>
              </template>
            </div>
          </template>
          <!-- 工时设置 -->
          <el-row>
            <el-form-item :label="$t('order.workTimeConfigure')">
              <div>
                <p>
                  {{ $t("order.config.waitReceiveConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.unclaimedOvertime"
                  ></el-input>
                  {{ $t("order.config.waitReceiveConfig2") }}
                </p>
                <p>
                  {{ $t("order.config.waitExecuteConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.firstExecutedOvertime"
                  ></el-input>
                  {{ $t("order.config.waitExecuteConfig2") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.secondaryExecutedOvertime"
                  ></el-input>
                  {{ $t("order.config.waitExecuteConfig3") }}
                </p>
                <p>
                  {{ $t("order.config.executingConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.completedOvertime"
                  ></el-input>
                  {{ $t("order.config.executingConfig2") }}
                </p>
                <p>
                  {{ $t("order.config.overtimeConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.timeReportDuty"
                  ></el-input>
                  {{ $t("order.config.overtimeConfig2") }}
                </p>
                <p>
                  {{ $t("order.config.overtimeConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.timeReportDept"
                  ></el-input>
                  {{ $t("order.config.overtimeConfig3") }}
                </p>
                <p>
                  {{ $t("order.config.overtimeConfig1") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.timeReportSuperiorDept"
                  ></el-input>
                  {{ $t("order.config.overtimeConfig4") }}
                  <el-input
                    class="input"
                    maxlength="3"
                    :show-word-limit="true"
                    size="small"
                    v-model="inspectionPlan.form.timeReportSuperiorMax"
                  ></el-input>
                  {{ $t("order.config.overtimeConfig5") }}
                </p>
              </div>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col :span="24" align="center">
              <el-button type="primary" @click="createInspectionPlan"
                >{{ $t("commons.save") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <!--计划清单-->
      <el-tab-pane
        v-if="orderTab"
        :label="$t('inspection.plan.list')"
        :name="orderTab.tabName"
        :key="orderTab.tabName"
        closable
      >
        <el-row>
          <div class="topname">
            计划名称：{{ planRowData.inspectionPlanName }}
          </div>
        </el-row>
        <el-row>
          <el-col
            :style="{
              border: '1px solid #DCDFE6',
              width: 'calc(33% - 7px)',
              height: '510px',
            }"
          >
            <el-row class="txbtnGroup">
              <el-button type="text" size="mini" @click="checkAllOrClear(1)"
                >全选</el-button
              >
              <!--<el-button type="text" size="mini" @click="checkAllOrClear(-1)">清空</el-button>-->
              <el-button type="text" size="mini" @click="saveTreeCheck"
                >保存所选</el-button
              >
            </el-row>
            <el-tree
              :data="positionTree"
              @check="handlePositionCheckChange"
              node-key="positionId"
              :show-checkbox="false"
              :auto-expand-parent="true"
              :default-expand-all="true"
              :highlight-current="true"
              ref="orderTree"
              class="orderMinHeight diyTree"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-checkbox
                  v-model="data.isChecked"
                  :disabled="isDisable(data)"
                  :label="data.isChecked"
                  >{{ node.label }}</el-checkbox
                >
                <!--<span @click="clickNode(data)">{{ node.label }}</span>-->
              </span>
            </el-tree>
          </el-col>
          <el-col
            :style="{
              border: '1px solid #DCDFE6',
              width: 'calc(34% - 6px)',
              margin: '0 10px',
              height: '510px',
            }"
          >
            <div class="xjPositions">
              巡检位置
              <div class="p_right">
                按顺序巡检
                <el-radio
                  v-model="bySort"
                  label="Y"
                  class="mr12"
                  @change="sortChange"
                  >是</el-radio
                >
                <el-radio v-model="bySort" label="N" @change="sortChange"
                  >否</el-radio
                >
              </div>
            </div>
            <ul class="planOrderUl orderMinHeight">
              <vuedraggable
                class="wrapper"
                v-model="inspectionOrderList"
                @end="sortChange"
                :disabled="isHttping"
                :animation="200"
              >
                <transition-group>
                  <template v-for="(item, dex) in inspectionOrderList">
                    <li
                      :key="item.positionId"
                      :class="{ liIsActive: tbBypositionId == item.positionId }"
                      :style="{ marginBottom: '2px' }"
                      @click="clickNode(item)"
                    >
                      <span class="sortdex">{{ dex + 1 }}</span>
                      {{ item.descName }}
                      <span class="delIcon" @click.stop="delCheckIt(item, dex)"
                        >×</span
                      >
                    </li>
                  </template>
                </transition-group>
              </vuedraggable>
            </ul>
          </el-col>
          <el-col class='overyBox'>
            <template v-if="tbBypositionId">
              <div class="partName">巡检要求</div>
              <ul class="planRightOrder orderMinHeight">
                <li>
                  {{ $t("inspection.position") }}:{{
                    updateInspectionOrder.positionName
                  }}
                </li>
                <li>
                  {{ $t("inspection.ifScan") }}:
                  <el-switch
                    v-model="updateInspectionOrder.isSignIn"
                    active-value="Y"
                    :disabled='updateInspectionOrder.isPhotograph=="Y"'
                    inactive-value="N"
                  ></el-switch>
                </li>
                <li>
          				是否拍照:
                  <el-switch v-model="updateInspectionOrder.isPhotograph"
                  	:disabled='disTakePho'
                    active-value="Y" inactive-value="N"  @change='takePhotoChange'></el-switch>
                </li>
                <template v-if="updateInspectionOrder.qrText != null">
                  <li>
                    <vue-qr
                      :text="updateInspectionOrder.qrText"
                      :size="200"
                    ></vue-qr>
                  </li>
                </template>
                <li>巡查标准备注:</li>
                <li @dblclick="showAllnotes=true">
                  <el-input
									  type="textarea" maxlength="1000"
  									show-word-limit
									  :rows="2"
									  placeholder="请输入内容"
									  v-model="updateInspectionOrder.notes">
									</el-input>
                </li>
                <li style="text-align: right;">
                  <el-button type="primary" @click="savePositionInspection"
                    >{{ $t("commons.save") }}
                  </el-button>
                </li>
              </ul>
            </template>
          </el-col>
        </el-row>
        <template v-if="tbBypositionId">
          <el-row :style="{ margin: '20px 0' }">
            <el-button type="primary" @click="openItemModal(2)" :disabled='updateInspectionOrder.isPhotograph=="Y"'
              >{{ $t("inspection.addItem") }}
            </el-button>
            <el-button type="primary" @click="openItemModal(1)" :disabled='updateInspectionOrder.isPhotograph=="Y"'
              >{{ $t("inspection.addDevice") }}
            </el-button>
          </el-row>
          <el-row>
            <el-table
              v-loading="inspectionItem.listLoading"
              :data="inspectionItem.list"
              element-loading-text="loading"
              border
              fit
              highlight-current-row
              stripe
            >
              <el-table-column :label="$t('commons.actions')">
                <template slot-scope="scope">
                  <el-tooltip
                    :content="$t('commons.edit')"
                    placement="bottom"
                    :open-delay="openDelay"
                  >
                    <el-button
                      size="mini"
                      icon="el-icon-edit"
                      type="primary"
                      circle
                      @click="updateInspectionItem(scope.row)"
                    >
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('commons.delete')"
                    placement="bottom"
                    :open-delay="openDelay"
                  >
                    <el-button
                      size="mini"
                      icon="el-icon-delete"
                      type="danger"
                      circle
                      @click="deleteInspectionItem(scope.row.itemPlanId)"
                    >
                    </el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column :label="$t('inspection.name')">
                <template slot-scope="scope">
                  <span>{{ scope.row.itemName }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('clocky.jobType')">
                <template slot-scope="scope">
                  <span>
                    <template v-if="scope.row.itemType == 1">
                      {{ $t("inspection.device") }}
                    </template>
                    <template v-else>
                      {{ $t("inspection.item") }}
                    </template>
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('inspection.ifPhoto')">
                <template slot-scope="scope">
                  <span>
                    <template v-if="scope.row.isPhotograph == 'Y'">
                      {{ $t("commons.yes") }}
                    </template>
                    <template v-else>
                      {{ $t("commons.no") }}
                    </template>
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('commons.template')">
                <template slot-scope="scope">
                  <span>{{ scope.row.templateName }}</span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              v-show="inspectionItem.total > 0"
              :total="inspectionItem.total"
              :page.sync="inspectionItem.listQuery.current"
              :limit.sync="inspectionItem.listQuery.rowCount"
              @pagination="getInspectionItemPage"
            />
          </el-row>
        </template>
      </el-tab-pane>
    </el-tabs>

    <!-- 编辑巡检点或巡检项 -->
    <el-dialog
      v-if="inspectionItem.addDialogVisible"
      :visible.sync="inspectionItem.addDialogVisible"
      :before-close="cancelInspectionItemSave"
      width="20%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <title-icon />{{ inspectionItem.title }}
      </template>
      <el-form
        label-position="top"
        :model="inspectionItem.form"
        ref="inspectionItemForm"
        :rules="inspectionItemFormRules"
      >
        <el-input
          type="hidden"
          class="hidden"
          v-model="inspectionItem.form.itemPlanId"
        ></el-input>
        <el-input
          type="hidden"
          class="hidden"
          v-model="inspectionItem.form.relationId"
        ></el-input>
        <el-form-item :label="$t('inspection.template.name')" prop="templateId">
          <!--巡检模版 ych-->

          <el-cascader
            v-model="inspectionItem.form.templateId"
            @change="changeTemplate($event)"
            :show-all-levels="false"
            :options="tempTrees"
            :props="treesPros"
            style="width: 100%"
          ></el-cascader>

          <!--<el-select
            v-model=
            @change="changeTemplate($event)"
            filterable
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in inspectionItem.templateList"
              :key="item.templateId"
              :value="item.templateId"
              :label="item.templateName"
            >
            </el-option>
          </el-select>-->
        </el-form-item>
        <el-form-item :label="inspectionItem.label" prop="itemName">
          <template v-if="inspectionItem.form.itemType == 1">
            <el-select
              v-model="inspectionItem.form.itemName"
              clearable
              @change="changeDevice"
              style="width: 100%"
            >
              <el-option
                v-for="item in inspectionItem.deviceList"
                :key="item.deviceId"
                :value="item.deviceId"
                :label="item.deviceName"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else>
            <el-input
              maxlength="50"
              :show-word-limit="true"
              v-model="inspectionItem.form.itemName"
            ></el-input>
          </template>
        </el-form-item>
        <el-form-item :label="$t('inspection.ifPhoto')">
          <el-switch
            v-model="inspectionItem.form.isPhotograph"
            active-value="Y"
            inactive-value="N"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelInspectionItemSave"
          >{{ $t("commons.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="handleInspectionItemSave"
          :disabled="is_click"
          v-loading.fullscreen.lock="inspectionItem.createLoading"
          >{{ $t("commons.save") }}
        </el-button>
      </div>
    </el-dialog>
    <TPrintCodes
      :isOpen="printInfo.open"
      @close="printInfo.open = false"
      :datas="printInfo.arrs"
    />
    
    
    <el-dialog title="巡查备注" :visible.sync="showAllnotes" width="600px" >
		  	
		  <div style="padding-bottom: 20px;">
		  	<el-input
				  type="textarea" maxlength="1000"
					show-word-limit
				  :rows="30"
				  placeholder="请输入内容"
				  v-model="updateInspectionOrder.notes">
				</el-input>
				<div style="padding-top: 12px;text-align: right;">
					<el-button type="primary" @click='showAllnotes=false'>确定</el-button>
				</div>
		  </div>
		</el-dialog>
  </div>
</template>

<script>
import {
  getInspectionPage,
  getCompanyList,
  getProjectList,
  getPositionChildNode,
  updateInspectionPosition,
  getInspectionOrder,
  enableInspection,
  stopInspection,
  deleteInspection,
  getCompanyDutyList,
  getProjectPositionTree,
  createInspectionPlan,
  getInspectionPlanById,
  updateInspectionPlan,
  getPositionInspection,
  updatePositionInspection,
  getInspectionItemPage,
  getDeviceList,
  addItem,
  updateItem,
  deleteItem,
} from "@/api/business/inspection/tenant/plan";
import vuedraggable from "vuedraggable";
import { getTemplateList } from "@/api/business/inspection/tenant/template";
import { listToTree } from "@/utils/tree";
import Pagination from "@/components/Pagination";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vueQr from "vue-qr";
import TTable from "@/components/YTable/TTable.vue";
import TPrintCodes from "@/components/YTable/TPrintCodes.vue";
const defineTab = {
  planTab: {
    tabName: "1",
  },
  editTab: {
    tabName: "2",
  },
  orderTab: {
    tabName: "3",
  },
};

export default {
  name: "InspectionPlan",
  components: {
    Pagination,
    TreeSelect,
    vuedraggable,
    vueQr,
    TTable,
    TPrintCodes,
  },
  data() {
    const dutyIdsValid = (rule, value, callback) => {
      if (!this.exePlanCycle.dutyIds.length) {
        callback(new Error(this.$t("validate.required")));
      } else {
        callback();
      }
    };
    const validatorstartTime = (rule, value, callback, Index) => {
      if (this.supervisoryPlanCycle.dutyList[0].list[Index].startDay) {
        callback();
      } else {
        callback(new Error(this.$t("validate.required")));
      }
    };
    return {
    	planArrs:[
    		{name:'巡检计划',val:1},
    		{name:'维保计划',val:2},
    	],
    	showAllnotes:false,
    	TimeOptions:[0,5,10,15,20,25,30,35,40,45,50,55,60],
      tbBypositionId: "", //通过id查看巡检列表的数据
      is_click: false, //防止暴力点击
      validatorstartTime,
      weekList: [],
      nowDayList: [],
      companyList: [],
      projectList: [],
      editProjectList: [],
      editDutyList: [],
      editPositionList: [],
      positionTree: [],
      openDelay: 1000,
      editTitle: null,
      isEdit: false,
      printInfo: {
        open: false,
        arrs: [],
      },
      activeTabName: defineTab.planTab.tabName,
      //计划编辑或新增tab页
      editTab: null,
      //维护清单tab页
      orderTab: null,
      isLoad: false,
      timer: null,
      cycleObj: {
        1: "每月",
        2: "每周",
        3: "每日",
        4: "每次",
      },
      bySort: "N", //是否按顺序巡检
      tableTitle: [
        {
          name: "项目名称",
          prop: "projectName",
        },
        {
          name: "计划名称",
          prop: "inspectionPlanName",
        },
        {
          name: "计划类型",
          prop: "planTypeStr",
        },
        {
          name: "巡检周期",
          prop: "cycleTypeStr",
        },
        {
          name: "执行岗位",
          prop: "exDutyNames",
        },
        {
          name: "督办岗位",
          prop: "supDutyNames",
        },
        {
          name: "生效时间",
          prop: "effectDate",
        },
        {
          name: "失效时间",
          prop: "expireDate",
        },
        {
          name: "更新时间",
          prop: "lastUpdateDate",
        },
        {
          name: "是否启用",
          slotName: "yesEnable",
        },
        {
          name: "操作",
          width: "288",
          fixed: "right",
          slotName: "operating",
        },
      ],
      inspectionPlan: {
        listLoading: false,
        addDialogVisible: false,
        list: [],
        total: 0,
        listQuery: {
          current: 1,
          rowCount: 10,
          companyId: null,
          projectId: null,
          inspectionPlanName: null,
          dutyName: null,
          status: null,
          planType:null,
        },
        form: {
          inspectionPlanId: null,
          inspectionPlanName: null,
          projectId: null,
          projectName: null,
          planDutyList: [],
          status: null,
          rootPositionId: null,
          effectDate: null,
          expireDate: null,
          advanceTime: 0,
          cycleType: null,
          unclaimedOvertime: null,
          firstExecutedOvertime: null,
          secondaryExecutedOvertime: null,
          completedOvertime: null,
          timeReportDuty: null,
          timeReportDept: null,
          timeReportSuperiorDept: null,
          timeReportSuperiorMax: null,
          planType:null,
        },
        formRules: {
          companyId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          projectId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          inspectionPlanName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          rootPositionId: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          advanceTime: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          effectDate: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          expireDate: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          cycleType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          dutyIds: [
            {
              required: true,
              trigger: ["blur", "change"],
              validator: dutyIdsValid,
            },
          ],
        },
      },
      tempTrees: [],
      treesPros: {
        value: "templateId",
        label: "templateName",
        children: "inspectionTemplateVOS",
        emitPath: false,
      },
      inspectionItem: {
        deviceList: [],
        templateList: [],
        createLoading: false,
        addDialogVisible: false,
        label: null,
        title: null,
        total: 0,
        list: [],
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          positionId: null,
          inspectionPlanId: null,
        },
        form: {
          itemPlanId: null,
          inspectionPlanId: null,
          isPhotograph: "N",
          itemType: null,
          itemName: null,
          positionId: null,
          relationId: null,
          templateId: null,
        },
      },
      isHttping: false,
      //清单列表
      inspectionOrderList: [],
      updateInspectionOrder: {
        qrText: null,
        positionName: null,
        isSignIn: "N",
        isPhotograph: "N",
        notes: "",
        inspectionPlanId: null,
        positionId: null,
        planPositionList: [],
      },
      supervisoryPlanCycle: {
        dutyList: [],
      },
      exePlanCycle: {
        dutyIds: null,
        list: [
          {
            startWeekDay: null,
            startDay: null,
            startTime: null,
            endWeekDay: null,
            endDay: null,
            endTime: null,
            cycleType: null,
            nextDayFlag: null,
          },
        ],
        form: {
          startWeekDay: null,
          startDay: null,
          startTime: null,
          endWeekDay: null,
          endDay: null,
          endTime: null,
          cycleType: null,
          nextDayFlag: null,
        },
      },
      inspectionItemFormRules: {
        itemName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        templateId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
      planRowData: {
        inspectionPlanName: "",
      },
    };
  },
  created() {
    this.searchCondition();
    this.getCompanyList();
    this.getTemplate();
    this.getTemplateArrs();
    //let nowDay = new Date().getDate();
    this.nowDayList = [];
    for (let i = 1; i <= 27; i++) {
      this.nowDayList.push({
        count: i + "",
      });
    }
    let week = 7;
    this.weekList = [];
    for (let i = 1; i <= week; i++) {
      let label;
      if (i == 1) {
        label = this.$t("commons.sunday");
      }
      if (i == 2) {
        label = this.$t("commons.monday");
      }
      if (i == 3) {
        label = this.$t("commons.tuesday");
      }
      if (i == 4) {
        label = this.$t("commons.wednesday");
      }
      if (i == 5) {
        label = this.$t("commons.thursday");
      }
      if (i == 6) {
        label = this.$t("commons.friday");
      }
      if (i == 7) {
        label = this.$t("commons.saturday");
      }
      this.weekList.push({
        value: i + "",
        label: label,
      });
    }
  },
  computed: {
    planTab() {
      return defineTab.planTab;
    },
    disTakePho() {
    		let res=false;
    		if(this.inspectionItem.list.length>0){
    			this.updateInspectionOrder.isPhotograph='N';
    			res=true;
    		}
	      return res;
	    },
  },
  methods: {
  	openAllText(){
  		
  		console.log('AAAAAAAAAAA')
  		
  	},
  	takePhotoChange(){
  		if(this.updateInspectionOrder.isPhotograph=='Y'){
  			this.updateInspectionOrder.isSignIn='Y';
  		}
  	},
    expiredateChange(val) {
      this.inspectionPlan.form.expireDate = val + 1000 * 60 * 60 * 24 - 999;
      //    console.log("失效时间change：", this.inspectionPlan.form.expireDate);
    },
    //当切换模版，巡        检项为空时 将模版名称赋予巡检项
    changeTemplate(templateId) {
      console.log("templateId", templateId);

      if (this.inspectionItem.form.itemType != 1) {
        this.$confirm(
          this.$t("inspection.template.itemNamePrompt"),
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancalButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            let item = this.inspectionItem.templateList.find((item) => {
              return item.templateId == templateId;
            });
            this.inspectionItem.form.itemName = item.templateName;
          })
          .catch((error) => {
            console.log(`未赋值，原因 => ${error}`);
          });
      }
    },
    duty2String(obj) {
      if (obj) {
        let deptId = obj.deptId ? obj.deptId : "";
        return obj.dutyId + "-" + deptId;
      }
      return "";
    },
    string2Duty(str) {
      let tmp = str.split("-");
      return {
        dutyId: tmp[0],
        deptId: tmp[1],
      };
    },
    // 关闭项Tab
    removeTab(tabName) {
      this.activeTabName = defineTab.planTab.tabName;
      if (tabName == "2") {
        this.editTab = null;
      }
      if (tabName == "3") {
        this.orderTab = null;
        this.updateInspectionOrder.positionId = null;
        this.updateInspectionOrder.positionName = null;
      }
    },
    searchCondition() {
      this.inspectionPlan.listQuery.current = 1;
      this.getInspectionPage();
    },

    async sortChange() {
      //				console.log('sortChange',JSON.parse(JSON.stringify(this.inspectionOrderList)))
      this.isHttping = true;
      this.inspectionOrderList.map((it, dex) => {
        it.sortField = dex + 1;
        it.isOrderly = this.bySort;
      });
      let params = {
        planPositionList: this.inspectionOrderList,
      };
      console.log("sortChange", JSON.parse(JSON.stringify(params)));
      let res = await this.ApiHttp(
        "/inspection/tenant/inspection/position/sort",
        this.inspectionOrderList,
        "post"
      );
      this.isHttping = false;
      return true;
    },
    async downQrCodes(row) {
      //下载二维码

      let res = await this.ApiHttp(
        "/inspection/tenant/inspection/position/list?inspectionPlanId=" +
          row.inspectionPlanId
      );
      if (res) {
        this.printInfo.open = true;
        let Arrs = [];
        res.map((it) => {
          let objs = {
            name: it.allPositionName,
            qrText: `{"type":"POSITION","positionId":` + it.positionId + `}`,
          };
          Arrs.push(objs);
        });
        this.printInfo = {
          open: true,
          arrs: Arrs,
        };
        console.log(4444444, res);
      }
    },
		async tbRowTemporary(row){
			
			this.$confirm('确定要临时下发吗？', '提示').then(() => {
				this.ApiHttp('/inspection/tenant/inspection/plan/manualExecutionPlan', {"inspectionPlanId": row.inspectionPlanId}, 'post').then(res => {
					if(res) {
						this.getInspectionPage();
					}
				});
			}).catch(() => {});
	
		},
    getInspectionPage() {
      this.inspectionPlan.listLoading = true;
      getInspectionPage(this.inspectionPlan.listQuery).then((response) => {
        if (response && response.rows) {
          response.rows.map((it) => {
            it.effectDate = this.common.getTimeMmss(it.effectDate);
            it.expireDate = this.common.getTimeMmss(it.expireDate);
            it.lastUpdateDate = this.common.getTimeMmss(it.lastUpdateDate);
            it.cycleTypeStr = this.cycleObj[it.cycleType];
            if(it.planType) it.planTypeStr=it.planType=='2'?'维保计划':'巡检计划';
            it.exDutyNames = "";
            it.supDutyNames = "";
            it.executeDutyList = it.planDutyList.filter((o) => {
              if (o.dutyType == 1) {
                it.exDutyNames += o.dutyName + " / ";
                return o;
              }
            });
            it.superviseDutyList = it.planDutyList.filter((o) => {
              if (o.dutyType == 2) {
                it.supDutyNames += o.dutyName + " / ";
                return o;
              }
            });
            if (it.exDutyNames) {
              it.exDutyNames = it.exDutyNames.substring(
                0,
                it.exDutyNames.length - 2
              );
            }
            if (it.supDutyNames) {
              it.supDutyNames = it.supDutyNames.substring(
                0,
                it.supDutyNames.length - 2
              );
            }
          });
        }
        this.inspectionPlan.total = response.total;
        this.inspectionPlan.list = response.rows;
        this.inspectionPlan.listLoading = false;
      });
    },
    getCompanyList() {
      getCompanyList().then((res) => {
        this.companyList = res;
      });
    },
    getProjectList(companyId) {
      getProjectList({
        companyId: companyId,
      }).then((res) => {
        this.projectList = res;
      });
    },
    getEditProjectList(companyId) {
      getProjectList({
        companyId: companyId,
      }).then((res) => {
        this.editProjectList = res;
      });
    },
    getProjectPositionTree(projectId) {
      if (projectId != null) {
        getProjectPositionTree({
          projectId: projectId,
        }).then((response) => {
          let data = listToTree(response, "positionId", "parentId");
          this.recursivePosition(data);
        });
      }
    },
    deleteExePlanDate(index) {
      if (this.exePlanCycle.list.length == 1) {
        return;
      }
      this.exePlanCycle.list.splice(index, 1);
    },
    addExePlanDate() {
      this.exePlanCycle.list.push({
        startWeekDay: null,
        startDay: null,
        startTime: null,
        endWeekDay: null,
        endDay: null,
        endTime: null,
        cycleType: this.inspectionPlan.form.cycleType,
        nextDayFlag: null,
      });
    },
    deleteSupervisoryPlanDate(item, index) {
      if (item.list.length == 1) {
        return;
      }
      item.list.splice(index, 1);
    },
    addSupervisoryPlanDate(item) {
      item.list.push({
        startWeekDay: null,
        startDay: null,
        startTime: null,
        endWeekDay: null,
        endDay: null,
        endTime: null,
        cycleType: item.cycleType,
        nextDayFlag: null,
      });
    },
    //递归位置列表修改属性及数据结构
    recursivePosition(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursivePosition(v.children);
        }
        return v;
      });
      this.editPositionList = data;
    },
    companyChange() {
      if (this.inspectionPlan.listQuery.companyId) {
        this.inspectionPlan.listQuery.projectId = null;
        this.getProjectList(this.inspectionPlan.listQuery.companyId);
      }
    },
    companyEditChange() {
      if (this.inspectionPlan.form.companyId != null) {
        this.inspectionPlan.form.projectId = null;
        this.getEditProjectList(this.inspectionPlan.form.companyId);
        this.getCompanyDutyList(this.inspectionPlan.form.companyId);
      }
    },
    clearQueryForm() {
      this.inspectionPlan.listQuery = {
        current: 1,
        rowCount: 10,
        companyId: null,
        projectId: null,
        inspectionPlanName: null,
        dutyName: null,
        status: null,
        planType:null,
      };
    },
    clearPlanForm() {
      this.inspectionPlan.form = {
        inspectionPlanId: null,
        inspectionPlanName: null,
        projectId: null,
        projectName: null,
        planDutyList: [],
        status: null,
        rootPositionId: null,
        effectDate: null,
        expireDate: null,
        advanceTime: 0,
        cycleType: null,
        unclaimedOvertime: null,
        firstExecutedOvertime: null,
        secondaryExecutedOvertime: null,
        completedOvertime: null,
        timeReportDuty: null,
        timeReportDept: null,
        timeReportSuperiorDept: null,
        timeReportSuperiorMax: null,
        planType:null,
      };
      this.exePlanCycle.list = [
        {
          startWeekDay: null,
          startDay: null,
          startTime: null,
          endWeekDay: null,
          endDay: null,
          endTime: null,
          cycleType: null,
          nextDayFlag: null,
        },
      ];
      this.supervisoryPlanCycle.dutyList = [];
      this.exePlanCycle.dutyIds = [];
    },
    getCompanyDutyList(companyId) {
      getCompanyDutyList({
        companyId: companyId,
      }).then((res) => {
        this.editDutyList = res;
      });
    },
    openPlanEditTab(inspectionPlanId) {
      this.editTab = defineTab.editTab;
      if (inspectionPlanId == null) {
        // 新建
        this.isEdit = false;
        this.clearPlanForm();
        this.editTitle = this.$t("inspection.plan.add");
      } else {
        // 编辑
        this.isEdit = true;
        this.isLoad = true;
        this.editTitle = this.$t("inspection.plan.edit");
        getInspectionPlanById({
          inspectionPlanId: inspectionPlanId,
        })
          .then((response) => {
            this.getCompanyDutyList(response.companyId);
            this.getEditProjectList(response.companyId);
            this.getProjectPositionTree(response.projectId);

            this.inspectionPlan.form = response;
            // 生效时间
            this.inspectionPlan.form.effectDate = Date.parse(
              response.effectDate
            );
            // 失效时间
            this.inspectionPlan.form.expireDate = Date.parse(
              response.expireDate
            );
            let dutyIds = [];
            let cycleType = "";
            let supervisoryPlanCycleDutyList = [];
            response.planDutyList.forEach((v) => {
              if (v.dutyType == 1) {
                dutyIds.push(this.duty2String(v));
                cycleType = v.planCycleList[0].cycleType;
                let exePlanCycleList = [];
                v.planCycleList.map((data) => {
                  data.startWeekDay = data.startWeekDay + "";
                  data.endWeekDay = data.endWeekDay + "";
                  exePlanCycleList.push(data);
                });
                this.exePlanCycle.list = exePlanCycleList;
              }

              if (v.dutyType == 2) {
                let list = [];
                v.planCycleList.map((data) => {
                  data.startWeekDay = data.startWeekDay + "";
                  data.endWeekDay = data.endWeekDay + "";
                  list.push(data);
                });
                let supervisoryDuty = {
                  dutyId: this.duty2String(v),
                  cycleType: v.planCycleList[0].cycleType + "",
                  list: list,
                };
                supervisoryPlanCycleDutyList.push(supervisoryDuty);
              }
            });
            this.supervisoryPlanCycle.dutyList = supervisoryPlanCycleDutyList;
            this.inspectionPlan.form.cycleType = cycleType + "";
            this.exePlanCycle.dutyIds = dutyIds;
            this.timer = setTimeout(() => {
              this.isLoad = false;
              clearTimeout(this.timer);
            }, 200);
          })
          .catch(() => {
            this.isLoad = false;
          });
      }
      this.activeTabName = defineTab.editTab.tabName;
    },
    async openPlanOrderTab(row) {
      this.planRowData = { ...row };
      this.orderTab = defineTab.orderTab;
      await getPositionChildNode({
        positionId: row.rootPositionId,
      }).then((response) => {
        //      	response.map(it=>{
        //      		this.$set(it,'isChecked',false)
        //      	})
        response.map((it) => (it.isChecked = false));
        let data = listToTree(response, "positionId", "parentId");
        this.recursiveAttr(data);
        this.tbBypositionId = "";
      });
      this.getInspectionOrder(row.inspectionPlanId);
      this.updateInspectionOrder.inspectionPlanId = row.inspectionPlanId;
      this.activeTabName = defineTab.orderTab.tabName;
      // 清空巡检点的内容，解决新打开的tab页，显示上次打开tab页的内容问题
      this.updateInspectionOrder.positionId = null;
      this.updateInspectionOrder.positionName = null;
    },
    //添加督办岗位
    addSupervisoryDuty() {
      if (this.supervisoryPlanCycle.dutyList.length == 3) {
        return;
      }
      this.supervisoryPlanCycle.dutyList.push({
        dutyId: null,
        cycleType: null,
        list: [
          {
            startDay: null,
            startTime: null,
            endDay: null,
            endTime: null,
            cycleType: null,
            nextDayFlag: null,
          },
        ],
      });
    },
    //执行岗位切换周期
    changeExeDutyCycle(val) {
      this.exePlanCycle.list = [
        {
          startDay: null,
          startTime: null,
          endDay: null,
          endTime: null,
          cycleType: val,
          nextDayFlag: null,
        },
      ];
    },
    //督办岗位切换周期
    changeSupervisoryDutyCycle(val, index) {
      this.supervisoryPlanCycle.dutyList[index].list = [
        {
          startDay: null,
          startTime: null,
          endDay: null,
          endTime: null,
          cycleType: val,
          nextDayFlag: null,
        },
      ];
    },
    //删除督办岗位
    deleteSupervisoryDuty(index) {
      this.supervisoryPlanCycle.dutyList.splice(index, 1);
    },
    getInspectionOrder(inspectionPlanId) {
      return new Promise((resolve, reject) => {
        getInspectionOrder({
          inspectionPlanId: inspectionPlanId,
        })
          .then((response) => {
            let isFrist = true;
            response.map((v) => {
              if (isFrist) {
                console.log("v.isOrderly", v);
                this.bySort = v.isOrderly || "N";
              }
              isFrist = false;
              v.descName = v.allPositionName;
              this.setItCheckTypeByVal(v); //查找出 并默认选中
            });
            this.inspectionOrderList = response;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 保存
    createInspectionPlan() {
    	console.log('this.supervisoryPlanCycle.dutyList[Index]',this.supervisoryPlanCycle.dutyList)
      this.$refs.inspectionPlanForm.validate((valid) => {
        if (valid) {
          let planDutyList = [];
          this.exePlanCycle.dutyIds.map((v) => {
            let dutyObj = this.string2Duty(v);
            let planDuty = {
              dutyId: dutyObj.dutyId,
              deptId: dutyObj.deptId,
              dutyType: 1,
              planCycleList: this.exePlanCycle.list,
            };
            planDutyList.push(planDuty);
          });
          this.supervisoryPlanCycle.dutyList.map((v) => {
            let dutyObj = this.string2Duty(v.dutyId);
            let planDuty = {
              dutyId: dutyObj.dutyId,
              deptId: dutyObj.deptId,
              dutyType: 2,
              planCycleList: v.list,
            };
            planDutyList.push(planDuty);
          });
          this.inspectionPlan.form.planDutyList = planDutyList;
          //保存时间校验
          if (this.checkValidateTime(planDutyList)) {
            if (this.inspectionPlan.form.inspectionPlanId == null) {
              // this.inspectionPlan.form.expireDate += 1000 * 60 * 60 * 24 - 999;
              createInspectionPlan(this.inspectionPlan.form).then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.operationSuccess"),
                  type: "success",
                });
                this.removeTab(defineTab.editTab.tabName);
                this.getInspectionPage();
              });
            } else {
              this.$confirm(
                this.$t("message.editConfirm"),
                this.$t("commons.warning"),
                {
                  confirmButtonText: this.$t("commons.confirm"),
                  cancelButtonText: this.$t("commons.cancel"),
                  type: "warning",
                }
              )
                .then(() => {
                  console.log(
                    "保存的失效时间：",
                    this.inspectionPlan.form.expireDate
                  );
                  // if (
                  //   String(this.inspectionPlan.form.expireDate).endsWith(
                  //     "000000"
                  //   )
                  // ) {
                  //   this.inspectionPlan.form.expireDate +=
                  //     1000 * 60 * 60 * 24 - 999;
                  // }
                  updateInspectionPlan(this.inspectionPlan.form).then(() => {
                    // 成功提示
                    this.$message({
                      message: this.$t("message.operationSuccess"),
                      type: "success",
                    });
                    this.removeTab(defineTab.editTab.tabName);
                    this.getInspectionPage();
                  });
                })
                .catch((error) => {
                  console.log(`未修改，原因 => ${error}`);
                });
            }
          }
        }
      });
    },
    // 判断结束时间<失效时间？
    judgmentTime(planDutyList) {
      const index = parseInt(this.inspectionPlan.form.cycleType);
      const expireDate = this.inspectionPlan.form.expireDate;
      let flag = true;

      switch (index) {
        // 每月
        case 1:
          const date = new Date(expireDate).getDate();
          planDutyList.forEach((item) => {
            item.planCycleList.forEach((obj) => {
              if (obj.endDay > date) {
                flag = false;
              } else {
                const time = obj.endTime.split(":");
                if (
                  obj.endDay == date &&
                  (Number(time[0] > 59) ||
                    (Number(time[0]) == 59 && Number(time[1]) > 59))
                ) {
                  flag = false;
                }
              }
            });
          });
          break;
        // 每周
        case 2:
          const weekNum = new Date(expireDate).getDay() + 1;
          planDutyList.forEach((item) => {
            item.planCycleList.forEach((obj) => {
              if (obj.endWeekDay > weekNum) {
                flag = false;
              } else {
                const time = obj.endTime.split(":");
                if (
                  obj.endWeekDay == weekNum &&
                  (Number(time[0] > 59) ||
                    (Number(time[0]) == 59 && Number(time[1]) > 59))
                ) {
                  flag = false;
                }
              }
            });
          });
          break;
        // 每日
        case 3:
          planDutyList.forEach((item) => {
            item.planCycleList.forEach((obj) => {
              const time = obj.endTime.split(":");
              if (
                obj.endDay == date &&
                (Number(time[0] > 59) ||
                  (Number(time[0]) == 59 && Number(time[1]) > 59))
              ) {
                flag = false;
              }
            });
          });
          break;
        // 每次
        case 4:
          planDutyList.forEach((item) => {
            item.planCycleList.forEach((obj) => {
              if (new Date(obj.endTime).getTime() >= expireDate) {
                flag = false;
              }
            });
          });
        default:
          console.log("err");
      }
      !flag &&
        this.$message({
          //        message: this.$t("inspection.invalidHint"),
          message: "结束时间超出失效时间范围，请重新配置结束时间",
          type: "error",
        });
      return flag;
    },
    //递归修改属性及数据结构
    recursiveAttr(row) {
      let data = row.map((v) => {
        v.label = v.positionName;
        v.id = v.positionId;
        if (v.children != undefined) {
          this.recursiveAttr(v.children);
        }
        return v;
      });
      this.positionTree = data;
    },
    checkAllOrClear(type) {
      //清空或者全选  type=1 全选   -1清空
      if (!type) return;
      let isTrue = type > 0;
      let that = this;

      function findIsCheck(arr) {
        arr.map((it) => {
          it.isChecked = isTrue;
          if (it.children) findIsCheck(it.children);
        });
      }
      findIsCheck(this.positionTree);
    },
    isDisable(datas) {
      let res = false;
      for (let it of this.inspectionOrderList) {
        if (datas.positionId == it.positionId) {
          res = true;
          break;
        }
      }
      return res;
    },
    saveTreeCheck() {
      //保存tree选择的数据 传递给后台
      let checks = this.getCheckNodes();
      console.log("checks", checks);

      if (checks.length < 1) {
        this.$message({
          type: "info",
          message: this.$t("inspection.leastOne"),
        });
        return;
      }

      let params = {
        inspectionPlanId: this.updateInspectionOrder.inspectionPlanId,
        planPositionList: checks,
      };
      updateInspectionPosition(params).then((res) => {
        console.log(res, "123132");
        //    	this.inspectionOrderList=checks;
        this.getInspectionOrder(params.inspectionPlanId).then((res) => {
          this.$nextTick(() => {
            this.sortChange();
          });
        });
      });
    },
    delCheckIt(data, dex) {
      if (this.inspectionOrderList.length < 2) {
        this.$message({
          type: "info",
          message: "最少保留一个",
        });
        return;
      }
      this.$confirm(
        "删除所选位置，若该位置已配置巡检项/巡检设备也将同时删除，是否确认删除？",
        "提示",
        {
          type: "warning",
        }
      )
        .then(() => {
          this.setItCheckTypeByVal(data, false);
          if (this.tbBypositionId === data.positionId) {
            this.tbBypositionId = "";
          }
          this.inspectionOrderList.splice(dex, 1);
          let params = {
            inspectionPlanId: this.updateInspectionOrder.inspectionPlanId,
            planPositionList: this.inspectionOrderList,
          };

          this.deleteIts(data);
          //						updateInspectionPosition(params).then(res => {
          //
          //
          //						});
        })
        .catch(() => {});
    },
    async deleteIts(data) {
      let res1 = await this.ApiHttp(
        "/inspection/tenant/inspection/position/delPosition?positionPlanId=" +
          data.positionPlanId,
        {},
        "delete"
      );
      if (res1) {
        let isOk = this.sortChange();
        if (!isOk) return;
        this.$message({
          type: "success",
          message: "删除成功",
        });
      }
    },
    setItCheckTypeByVal(data, isCk = true) {
      //通过positionId从treeData中找出数据  设置是否默认选中
      if (!data.positionId) return;
      let isFind = this.common.findTreeValBykeys(
        this.positionTree,
        "positionId",
        data.positionId
      );
      //  	console.log('isFind',this.positionTree,data,isFind)
      if (isFind) {
        isFind.isChecked = isCk;
      }
    },
    getCheckNodes() {
      //获取选中的节点内容
      let resArr = [];
      let that = this;

      function findIsCheck(arr) {
        arr.map((v) => {
          if (v.isChecked) {
            resArr.push(v);
          }
          if (v.children) findIsCheck(v.children);
        });
      }
      findIsCheck(this.positionTree);
      //  	console.log('选中的节点是',JSON.parse(JSON.stringify(resArr)))
      return resArr;
    },
    //更新计划清单
    handlePositionCheckChange(node) {
      let data = this.$refs.orderTree.getCheckedNodes();
      //    console.log('handlePositionCheckChange',node,data)
      if (data.length == 0 && this.inspectionOrderList.length > 0) {
        this.$message({
          message: this.$t("inspection.leastOne"),
          type: "info",
        });
        this.$refs.orderTree.setCheckedKeys([node.positionId]);
        return;
      }
      this.updateInspectionOrder.planPositionList = [];
      data.map((v) => {
        this.updateInspectionOrder.planPositionList.push({
          positionId: v.positionId,
          positionName: v.positionName,
          positionParentId: v.parentId,
        });
      });
      let params = {
        inspectionPlanId: this.updateInspectionOrder.inspectionPlanId,
        planPositionList: this.updateInspectionOrder.planPositionList,
      };
      updateInspectionPosition(params).then(() => {
        this.getInspectionOrder(this.updateInspectionOrder.inspectionPlanId);
      });
    },
    clickNode(data) {
      this.tbBypositionId = data.positionId;
      getPositionInspection({
        positionId: data.positionId,
        inspectionPlanId: this.updateInspectionOrder.inspectionPlanId,
      }).then((response) => {
        this.updateInspectionOrder.isSignIn = response.isSignIn;
        this.updateInspectionOrder.isPhotograph = response.isPhotograph||'N';
        this.updateInspectionOrder.notes = response.notes||'';
        this.updateInspectionOrder.positionName = response.positionName;
        this.updateInspectionOrder.positionId = data.positionId;
        this.updateInspectionOrder.qrText =
          `{"type":"POSITION","positionId":` + data.positionId + `}`;
        this.inspectionItem.listQuery.inspectionPlanId =
          this.updateInspectionOrder.inspectionPlanId;
        this.inspectionItem.listQuery.positionId = data.positionId;
        this.getInspectionItemPage();
        this.getDeviceList();
      });
      //    let nodes = this.$refs.orderTree.getCheckedKeys();
      //    if (nodes.indexOf(data.positionId) > -1) {
      //
      //    }
    },
    openItemModal(itemType) {
      this.clearItem();
      if (itemType == 1) {
        this.inspectionItem.title = this.$t("inspection.addDevice");
        this.inspectionItem.label = this.$t("inspection.device");
      } else {
        this.inspectionItem.title = this.$t("inspection.addItem");
        this.inspectionItem.label = this.$t("inspection.item");
      }
      this.inspectionItem.form.itemType = itemType;
      this.inspectionItem.addDialogVisible = true;
    },
    cancelInspectionItemSave() {
      this.inspectionItem.addDialogVisible = false;
    },
    changeDevice(deviceId) {
      let obj = this.inspectionItem.deviceList.find((item) => {
        //这里的chargingWorkNameList就是上面遍历的数据源
        return item.deviceId === deviceId;
        //筛选出匹配数据，是对应数据的整个对象
      });
      this.inspectionItem.form.itemName = obj.deviceName;
      this.inspectionItem.form.relationId = obj.deviceId;
    },
    handleInspectionItemSave() {
      //按钮防止暴力点击
      if (this.is_click) {
        return false;
      }
      this.$refs.inspectionItemForm.validate((v) => {
        this.is_click = true;
        setTimeout(() => {
          this.is_click = false;
        }, 2000);

        //  var last=Date.now();
        //   ()=>{
        //     var now=Date.now();
        //     if((now-last)>1000){
        //       alert
        //     }
        //     last=now;
        //   }

        if (v) {
          this.inspectionItem.form.inspectionPlanId =
            this.updateInspectionOrder.inspectionPlanId;
          this.inspectionItem.form.positionId =
            this.updateInspectionOrder.positionId;

          if (this.inspectionItem.form.itemPlanId == null) {
            addItem(this.inspectionItem.form).then(() => {
              this.inspectionItem.addDialogVisible = false;
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
              this.getInspectionItemPage();
            });
          } else {
            updateItem(this.inspectionItem.form).then(() => {
              this.inspectionItem.addDialogVisible = false;
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
              this.getInspectionItemPage();
            });
          }
        }
      });
    },
    updateInspectionItem(row) {
      if (row.itemType == 1) {
        this.inspectionItem.title = this.$t("inspection.editDevice");
        this.inspectionItem.label = this.$t("inspection.device");
      } else {
        this.inspectionItem.title = this.$t("inspection.editItem");
        this.inspectionItem.label = this.$t("inspection.item");
      }
      this.inspectionItem.form = row;
      this.inspectionItem.addDialogVisible = true;
    },
    deleteInspectionItem(itemPlanId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteItem({
            itemPlanId: itemPlanId,
          }).then(() => {
            this.$message({
              message: this.$t("message.operationSuccess"),
              type: "success",
            });
            this.getInspectionItemPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    clearItem() {
      this.inspectionItem.form = {
        itemPlanId: null,
        inspectionPlanId: null,
        isPhotograph: "N",
        itemType: null,
        itemName: null,
        positionId: null,
        relationId: null,
        templateId: null,
      };
    },
    getDeviceList() {
      getDeviceList({
        positionId: this.updateInspectionOrder.positionId,
      }).then((res) => {
        this.inspectionItem.deviceList = res;
      });
    },
    //获取模版列表
    getTemplate() {
      getTemplateList({
        templateLibraryId: null,
        templateName: null,
      }).then((res) => {
        this.inspectionItem.templateList = res;
      });
    },

    async getTemplateArrs() {
      let params = {
        isloading: false,
      };
      //				let res = await this.ApiHttp("/inspection/tenant/inspection/template/library/list", params);
      let res = await this.ApiHttp(
        "/inspection/tenant/inspection/template/library/templateList",
        params
      );
      if (res) {
        console.log("getTemplateArrs", res);
        res.map((it) => (it.templateName = it.templateLibraryName));
        this.tempTrees = res;
      }
    },

    getInspectionItemPage() {
      this.inspectionItem.listLoading = true;
      getInspectionItemPage(this.inspectionItem.listQuery).then((res) => {
        this.inspectionItem.total = res.total;
        this.inspectionItem.list = res.rows;
        this.inspectionItem.listLoading = false;
      });
    },
    savePositionInspection() {
      let params = {
        positionId: this.updateInspectionOrder.positionId,
        inspectionPlanId: this.updateInspectionOrder.inspectionPlanId,
        isSignIn: this.updateInspectionOrder.isSignIn,
        isPhotograph: this.updateInspectionOrder.isPhotograph,
        notes: this.updateInspectionOrder.notes,
      };
      updatePositionInspection(params).then(() => {
        // 成功提示
        this.$message({
          message: this.$t("message.operationSuccess"),
          type: "success",
        });
      });
    },

    switchChange(row) {
      console.log("switchChange", row.status);
      if (row.status == 1) {
        this.enableInspection(row.inspectionPlanId, row);
      } else {
        this.stopInspection(row.inspectionPlanId, row);
      }
    },
    //启用计划
    enableInspection(inspectionPlanId, row) {
      this.$confirm(
        this.$t("inspection.ifEnablePrompt"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          enableInspection({
            inspectionPlanId: inspectionPlanId,
          })
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
              this.getInspectionPage();
            })
            .catch((error) => {
              row.status = 0;
            });
        })
        .catch((error) => {
          row.status = 0;
          console.log(`未启用，原因 => ${error}`);
        });
    },
    //暂停计划
    stopInspection(inspectionPlanId, row) {
      this.$confirm(
        this.$t("inspection.ifStopPrompt"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          stopInspection({
            inspectionPlanId: inspectionPlanId,
          })
            .then(() => {
              // 成功提示
              this.$message({
                message: this.$t("message.operationSuccess"),
                type: "success",
              });
              this.getInspectionPage();
            })
            .catch((error) => {
              row.status = 1;
            });
        })
        .catch((error) => {
          row.status = 1;
          console.log(`未暂停，原因 => ${error}`);
        });
    },
    //删除计划
    deleteInspection(inspectionPlanId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteInspection({
            inspectionPlanId: inspectionPlanId,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.operationSuccess"),
              type: "success",
            });
            this.searchCondition();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    checkValidateTime(planDutyList) {
      let flag = true;
      if (
        this.inspectionPlan.form.effectDate >
        this.inspectionPlan.form.expireDate
      ) {
        this.$message({
          message: this.$t("inspection.invalidTimePrompt"),
          type: "info",
        });
        return false;
      }
      if (planDutyList != null) {
        for (let ii = 0; ii < planDutyList.length; ii++) {
          const v = planDutyList[ii];
          for (let i = 0; i < v.planCycleList.length; i++) {
            const obj = v.planCycleList[i];
            //每月
            if (obj.cycleType == 1) {
              // 非空校验
              if (!obj.startDay || !obj.startTime) {
                this.$message.info("请选择开始日期和时间");
                return false;
              }
              if (!obj.endDay || !obj.endTime) {
                this.$message.info("请选择结束日期和时间");
                return false;
              }
              // 日期校验
              if (
                obj.startDay > obj.endDay ||
                (obj.startDay == obj.endDay && obj.startTime > obj.endTime)
              ) {
                this.$message({
                  message:
                    this.$t("inspection.monthCyclePrompt") +
                    obj.startTime +
                    this.$t("inspection.unGreatDate") +
                    obj.endTime,

                  type: "info",
                });
                return false;
              }
              if (obj.endDay - obj.startDay > 4) {
                this.$message({
                  message: this.$t("inspection.monthPrompt"),
                  type: "info",
                });
                return false;
              }
            }

            //每周
            else if (obj.cycleType == 2) {
              // 非空校验
              if (!obj.startWeekDay || !obj.startTime) {
                this.$message.info("请选择开始日期和时间");
                return false;
              }
              if (!obj.endWeekDay || !obj.endTime) {
                this.$message.info("请选择结束日期和时间");
                return false;
              }
              if (
                obj.startWeekDay > obj.endWeekDay ||
                (obj.startWeekDay == obj.endWeekDay &&
                  obj.startTime > obj.endTime)
              ) {
                this.$message({
                  message:
                    this.$t("inspection.weekCyclePrompt") +
                    obj.startTime +
                    this.$t("inspection.unGreatDate") +
                    obj.endTime,
                  type: "info",
                });
                return false;
              }
            }
            //每日
            else if (obj.cycleType == 3) {
              // 非空校验
              if (!obj.startTime) {
                this.$message.info("请选择开始时间");
                return false;
              }
              if (!obj.endTime) {
                this.$message.info("请选择结束时间");
                return false;
              }
              if (obj.nextDayFlag == "N" && obj.startTime > obj.endTime) {
                this.$message({
                  message:
                    this.$t("inspection.dayCyclePrompt") +
                    obj.startTime +
                    this.$t("inspection.unGreatDate") +
                    obj.endTime,
                  type: "info",
                });
                return false;
              }
            }
            //单次
            else if (obj.cycleType == 4) {
              // 非空校验
              if (!obj.startTime) {
                this.$message.info("请选择开始时间");
                return false;
              }
              if (!obj.endTime) {
                this.$message.info("请选择结束时间");
                return false;
              }
              if (obj.startTime > obj.endTime) {
                this.$message({
                  message:
                    this.$t("inspection.onceCyclePrompt") +
                    obj.startTime +
                    this.$t("inspection.unGreatDate") +
                    obj.endTime,
                  type: "info",
                });
                return false;
              }
            }
          }
        }
      }
      flag = this.judgmentTime(planDutyList);
      return flag;
    },
    clearEmpty(field) {
      this.inspectionPlan.listQuery[field] = null;
    },
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .overyBox{
  	overflow-y: auto;
  	border: 1px solid #DCDFE6;
      width: calc(33% - 7px);
      height: 510px;
  }
.planOrderUl {
  list-style-type: none;
  li {
    font-size: 15px;
    cursor: pointer;
    line-height: 24px;
    padding: 0 6px;
    position: relative;
    .delIcon {
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;
      height: 12px;
      display: none;
      color: #f56c6c;
      border-radius: 50%;
    }
  }
  .sortdex {
    padding-right: 6px;
  }
  .liIsActive {
    color: #409eff;
    background: #f0f7ff;
  }
  li:hover {
    background: #f0f7ff;
  }
  li:hover .delIcon {
    display: block;
  }
}

.txbtnGroup,
.xjPositions {
  padding: 8px 20px;
  border-bottom: 1px solid #eee;
}
.xjPositions {
  font-size: 12px;
  line-height: 28px;
  position: relative;
  .p_right {
    position: absolute;
    right: 18px;
    top: 8px;
    .mr12 {
      margin: 0 8px;
    }
  }
}
.partName {
  font-size: 12px;
  line-height: 45px;
  border-bottom: 1px solid #eee;
  padding: 0 20px;
}
.planRightOrder{
	/*height: 85% !important;
	overflow-y: auto;*/
	li {
  margin-bottom: 10px;
}
} 

.input {
  width: 70px;
}

.orderMinHeight {
  height: calc(100% - 66px);
  overflow-y: auto;
  margin: 15px 20px;
  padding: 0px;
}

.diyTree.orderMinHeight {
  height: 437px;
}

::v-deep .el-loading-mask {
  transition: opacity 0s;
}

.topname {
  line-height: 20px;
  font-size: 13px;
  margin-bottom: 8px;
}
</style>